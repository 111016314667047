export enum CONTRACT_STATUS {
    ACTIVE = 'ACTIF',
    SUSPENDED = 'SUSPENDU',
    DEACTIVATED = 'DESACTIVE'
}

export enum OFFER_TYPE {
    PREMIUM = 'PREMIUM'
}

export enum POTENTIAL_DISCOUNT {
    MULTILIGNE = 'MULTILIGNE'
}

export interface ContexteOperateur {
    personneUnique: PersonneUnique;
    commandesCommercialesEnCours?: CommandeCommerciale[];
    contratsServiceNonResilies?: ContratService[];
}
export interface CommandeCommerciale {
    idCommande: string;
    offresPrincipales?: OffrePrincipale[];
}
export interface OffrePrincipale {
    idOffre: string;
    ligneMarche: string;
    ligneMarcheConvergent: string;
    niveauGammeConvergent?: string;
}
export interface ContratService {
    idContrat: string;
    offrePrincipale: OffrePrincipale;
}
export interface PersonneUnique {
    idPersonne:  string;
    remisesPotentielles?: RemisePotentielle[];
}

export interface RemisePotentielle {
    type: string;
}
