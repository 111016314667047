import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CONTRACT_STATUS } from '@interfaces/contract.interface';
import { CustomerProGpSearchModel } from '@interfaces/customer.interface';
import { CustomerProSearchModel } from '@models/customer/customer-pro-search.model';
import { CustomerSearchModel } from '@models/customer/customer-search.model';
import { ScoringService } from '@services/checkout/scoring.service';

@Component({
    selector: 'tlv-line',
    templateUrl: './line.component.html',
    styleUrls: ['./line.component.scss']
})
export class LineComponent implements OnInit {

    @Input() public customer: CustomerProGpSearchModel;
    @Input() public isLoading: boolean = false;
    @Output() public savedEvent: EventEmitter<string> = new EventEmitter<string>();

    public isBtnValid: boolean = false;
    public customerPro: CustomerProSearchModel;
    public customerGp: CustomerSearchModel;
    public isClientLockByScoring: boolean = false;

    constructor(
        private scoringService: ScoringService
    ) {
    }

    public ngOnInit(): void {
        if (this.customer instanceof CustomerProSearchModel){
            this.customerPro = this.customer;
        } else {
            this.customerGp = this.customer;
        }
        this.isBtnValid = this.customer.comptesFacturations.some((compteFacturation) =>
            compteFacturation.contratsPayes.some((contract) => CONTRACT_STATUS.ACTIVE === contract.statut));

        this.isClientLockByScoring = !!this.scoringService.scoring?.isClientBlockedByScoring();

        this.scoringService.scoring$.subscribe(() => {
            this.isClientLockByScoring = !!this.scoringService.scoring?.isClientBlockedByScoring();
        });
    }

    public nextStep(): void {
        this.savedEvent.emit('saved');
    }

}
