import { Model } from '../model';

export class BillingAccountModel extends Model {
    id: string;
    noCompte: string;
    statut: string;
    identifiantFonctionnelClient: string;
    adresseDeclaree: {
        numero: string;
        rue: string;
        ville: string;
        codePostal: string;
        codePays: string;
    };
    contratsPayes?: {
        codeCauseChgtStatut: string;
        msisdn: string;
        noReference: string;
        idTitulaire: string;
        idPfs: string;
        statut: string;
        dateCreation: Date;
        datePremiereActivation: Date;
        ligneMarche: string;
        offrePrincipale: {
            noOffre: string;
            libelle: string;
        };
        noVoip: string;
        loginBbox: string;
    }[];

    constructor(data: Partial<BillingAccountModel>){
        super(data);
    }

}
