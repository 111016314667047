import { AfterViewInit, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALUE_ACCESSOR, ValidationErrors,
    Validators,
    FormControl,
    FormGroup
} from '@angular/forms';
import { ConfigurationService } from '@common-modules';
import { BillingAccountModel } from '@models/customer/billing-account.model';
import { CustomerService } from '@services/customer/customer.service';
import { finalize, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import bind from 'src/app/helper/decorators/bind';
import { SalesService } from '@services/checkout/sales.service';
import { CONTRACT_STATUS } from '@interfaces/contract.interface';
import { OrderModel } from '@models/order/order.model';
import { SalesForceService } from '@services/salesforce.service';
import { CustomerStorage } from '@repositories/storages/customer.storage';
import { CustomerModel } from '@models/customer/customer.model';
@Component({
    selector: 'tlv-iban',
    templateUrl: './iban.component.html',
    styleUrls: ['./iban.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(()=> IbanComponent),
            multi: true
        }
    ]
})
export class IbanComponent implements OnInit, AfterViewInit, ControlValueAccessor {

    @Input() cartHasMobileRecovery: boolean;
    @Output() isLoading: EventEmitter<boolean> = new EventEmitter();

    public ibanForm: FormGroup = new FormGroup({
        iban: new FormControl<string | null>(null, [this._ibanValidator]),
        customerIban: new FormControl<number | null>(null, [Validators.required]),
        useIbanForPurchase: new FormControl<boolean | null>(null, []),
    });
    public isSubmitted: boolean = false;

    public editable: boolean = true;
    public isRenewal: boolean = false;
    public ibanLength: number = 27;
    public inputIcon: string;
    public canalPlusElig: boolean;
    public ibans: {masked: string; plain: string}[] = [];

    public currentCountryCode: string = 'FR';
    public orderRecovery: OrderModel;
    public maskedOrderRecoveryIban: string;

    private onChange: (value: boolean) => void;
    private onTouched: () => void;

    constructor(
        private configService: ConfigurationService,
        private customerService: CustomerService,
        private salesService: SalesService,
        private salesForceService: SalesForceService
    ) {}

    public ngOnInit(): void {
        this.orderRecovery = this.salesForceService.prefilledInfo.order;
        if (!this.orderRecovery) {
            const signedContracts = this.customerService.customer.signedContracts.filter(c=>c.status === CONTRACT_STATUS.ACTIVE);
            const billingAccounts: BillingAccountModel[] = this.customerService.customer.comptesFacturations
                .filter((account)=>signedContracts.some(c=>c.billingAccountId === account.id));
            if (billingAccounts.length) {
                const unDuplicatedIbans = new Set();
                this.ibans = billingAccounts.map((billingAccount: BillingAccountModel) => {
                    const iban: string  = (billingAccount as any)?.compteBancaire?.iban;
                    if (!iban || unDuplicatedIbans.has(iban)) {
                        return;
                    }
                    unDuplicatedIbans.add(iban);
                    return {
                        masked: this._maskIban(iban),
                        plain: iban
                    };
                }).filter(ib => !!ib);
            }
            this._getSavedIbanProspect();
            this.ibanForm.setValue({
                iban: null,
                customerIban: this.ibans.length ? 1 : null,
                useIbanForPurchase: !!this.ibans.length,
            });
            if (!this.ibans.length){
                this.ibanForm.get('customerIban').disable();
                this.ibanForm.get('iban').enable();
            } else {
                this.ibanForm.get('customerIban').enable();
                this.ibanForm.get('iban').disable();
            }
            this.ibanForm.get('useIbanForPurchase').valueChanges.subscribe(this._updateIbanType);
        }
        this.ibanForm.statusChanges.subscribe(this._submit);
    }

    public ngAfterViewInit(): void {
        if (this.ibans.length) {
            this.ibanForm.patchValue({
                iban: this.ibans[0].plain
            });
        }
        if (this.orderRecovery) {
            this.maskedOrderRecoveryIban = this._maskIban(this.orderRecovery.cart.payment.iban);
            this.ibanForm.get('iban').setValue(this.orderRecovery.cart.payment.iban, {emitEvent: false});
            this.ibanForm.get('iban').disable();
            this.ibanForm.get('customerIban').disable();
        }
    }

    public writeValue(): void { return null; }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    private _getSavedIbanProspect(): void {
        const iban = CustomerStorage.getItem<CustomerModel>(CustomerStorage.KEYS.CUSTOMER)?.iban;
        if (!this.customerService.customer.isClient && iban ) {
            this.ibans.push({plain: iban, masked: this._maskIban(iban)});
        }
    }

    @bind
    private _updateIbanType(value: boolean): void {
        this.isSubmitted = false;
        if (!!value) {
            this._updateCustomerIbans();
        } else {
            this.ibanForm.get('iban').reset(null, {emitEvent: false});
            this.ibanForm.get('iban').enable();
            this.ibanForm.get('customerIban').reset(0, {emitEvent: false});
            this.ibanForm.get('customerIban').disable();
            this.onChange(null);
        }
    }

    private _updateCustomerIbans(): void {

        if (this.ibanForm.get('customerIban').disabled) {
            this.ibanForm.get('customerIban').enable({emitEvent: false});
            this.ibanForm.get('iban').disable();
        }
        this.ibanForm.get('iban').setErrors(null, {emitEvent: false});
        this.ibanForm.patchValue({
            iban: null,
            useIbanForPurchase: true,
            customerIban: 0
        }, {emitEvent: false});
        this.onChange(this.isSubmitted ? true : null);
    }

    @bind
    private _submit(status): void {

        const isIbanSelectionLabel: () => boolean = () =>
            this.ibanForm.get('useIbanForPurchase').value &&
            +this.ibanForm.get('customerIban').value === 0;
        if (isIbanSelectionLabel()) {
            this.onChange(null);
            return;
        }

        if (status === 'VALID') {
            const iban: string = this.ibanForm.get('useIbanForPurchase').value ?
                this.ibans[(+this.ibanForm.get('customerIban').value - 1)].plain : this.ibanForm.get('iban').value;

            if (!iban) {
                this.onChange(null);
                return;
            }
            this.isLoading.emit(true);
            this.salesService.updateIban({iban}).pipe(
                finalize(() => this.isLoading.emit(false)),
                catchError((data) => {
                    this.ibanForm.get('iban').setErrors({ibanApi: { message: data.error.error_description }}, {emitEvent: false});
                    return of(null);
                })
            ).subscribe(
                (res) => {
                    this.customerService.saveIban(iban);
                    this.isSubmitted = true;
                    this.onChange(!!res && !isIbanSelectionLabel() && this.ibanForm.valid ? true : null);
                }
            );
        }
        this.onTouched();
    }

    @bind
    private _ibanValidator(control: AbstractControl): ValidationErrors | null {
        if (!control.value){
            return Validators.required(control);
        }
        const countryCode: string = control.value.substr(0, 2);
        const ibanMask: number = this.configService.data_refconf.checkout.iban.mask[countryCode.toUpperCase()];
        if (!ibanMask){
            return {pattern: true};
        }

        return  Object.assign({},
            Validators.required(control),
            Validators.pattern(RegExp(`${countryCode}[0-9]{2}[a-zA-Z0-9]{${(ibanMask - 4)}}`))(control),
            Validators.minLength(ibanMask)(control)
        );
    }


    private _maskIban(iban: string): string {
        if (!iban?.length) {
            return '';
        }

        let ibanMask: string = '';
        const ibanLength = iban.length;
        iban.split('').forEach((value, index) => {
            if ((index > 1 && index < ibanLength - 8) || index === ibanLength - 2 || index === ibanLength - 1) {
                ibanMask += '*';
            } else {
                ibanMask += value;
            }
        });
        return ibanMask;
    }

}
