import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
    Activation,
    CartModel,
    Fai,
    FaiBox,
    FaimUnlimited,
    Installation,
    Product,
    ProductFactory,
    QuoteContextFaiModel,
    TYPE_MARKETING
} from '@bytel/bytel-sales';
import bind from '@helper/decorators/bind';
import { ObjectOf } from '@helper/types';
import { GetDeepValue } from '@helper/utils';
import { ALLOWED_CATEGORIES } from '@interfaces/api/catalog.interface';
import { OrderModel } from '@models/order/order.model';
import { CatalogService } from '@services/catalog.service';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { CartService } from '@services/checkout/cart.service';
import { ScoringService } from '@services/checkout/scoring.service';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import { PromotionsService } from '@services/promotions.service';
import { SalesForceService } from '@services/salesforce.service';
import { Observable, catchError, forkJoin, map, mergeMap, of, tap, throwError } from 'rxjs';
import { EQUIPMENT_FILTER_LABELS, FAI_ROUTES } from 'src/app/constants/fai';
import {
    Resultat
} from '@bytel/pt-ihm-api-egide-controle-risque-vente-demander-offres-autorisees/dist/models/components/schemas/Resultat';

export interface IFaiBundle {
    cart: CartModel;
    fai: Fai;
    activation?: Activation;
    installation?: Installation;
    location: FaiBox;
    skus: string[];
}

export interface IFaiOffers { [key: string]: IFaiBundle[] }

@Component({
    selector: 'tlv-offers',
    templateUrl: './offers.component.html',
    styleUrls: ['./offers.component.scss']
})
export class OffersComponent {

    public selectedTech;
    public field = 'fai.prices.forever';
    public offers: IFaiOffers = {};
    public offerKeys: string[] = [];
    public reverse = true;
    public isLoading: boolean = false;
    public selectedOffer;
    public orderRecovery: OrderModel;
    public technoFilter: string[] = [];
    public canAddFaiInQuoteMixed: boolean = false;
    public loadingError: boolean = false;
    public offerWithManualPromotionZipCode: string[] = [];
    public offersConvergence: { [index: string]: boolean } = {};
    public proOfferLabel: string = 'offre_pro';
    public filterOffersByType: FormGroup = new FormGroup({
        offerType: new FormControl(
            'offre_gp', []
        ),
        sortByObligation: new FormControl(
            'all', []
        ),
        sortByEquipmentType: new FormControl(
            'all', []
        ),
        sortByPartner: new FormControl(
            'none', []
        ),
    });
    public filterEquipements: { [index: string]: string } = EQUIPMENT_FILTER_LABELS;
    public sortByPartners: { label: string; key: string }[] = [];
    public sortByObligations: { label: string; key: string }[] = [
        { label: 'Toutes les durées d\'engagement', key: 'all' },
        { label: 'Engagement 12 mois', key: 'monthly12' },
        { label: 'Engagement 24 mois', key: 'monthly24' }
    ];
    public scoringRules: Resultat;

    private _bundlesBacked: IFaiBundle[] = [];
    private _offerWithManualPromotion: string[];

    constructor(
        private readonly faiEligibility: FaiEligibilityService,
        private readonly catalogService: CatalogService,
        private readonly cartService: CartService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly promotionService: PromotionsService,
        private readonly scoringService: ScoringService,
        private readonly salesForceService: SalesForceService,
        private cartTelesalesService: CartTeleSalesService,
        protected router: Router) {
        this.orderRecovery = this.salesForceService.prefilledInfo.order;
        this.activatedRoute.queryParams.subscribe((data) => {
            this.technoFilter = data?.techno?.split(',').filter(tech => !!tech);
        });

        forkJoin(
            [
                this.catalogService.getCategoriesAndChilds<Product>([ALLOWED_CATEGORIES.SIMOFAI_TELESALES], true),
                this.catalogService.getProductsByGencodes<Fai>(this.faiEligibility.currentCart.eligibility.getOCs()),
                this.cartTelesalesService.canAddFaiInQuote()
            ]
        ).pipe(
            map(([
                productFai, productOCs, canAddFaiInQuoteMixed
            ]) =>  {
                this.canAddFaiInQuoteMixed = canAddFaiInQuoteMixed;
                if (this.canAddFaiInQuoteMixed) {
                    return productOCs.filter(pOCs => productFai[0].products.find(p => pOCs.gencode === p.gencode));
                }
                return productOCs;
            }),
            map(productOCs => productOCs.filter(this._filterTechno(this.technoFilter))),
            map(productOCs => productOCs.filter(this._filterFaimInStock())),
            mergeMap((productOCs) => productOCs.length ?
                this.faiEligibility.getConvergenceForOffers(productOCs.map(oc => oc.gencode))
                    .pipe(
                        tap((convergence) => {
                            this.offersConvergence = convergence;
                        }),
                        map(() => productOCs)
                    ) : of(productOCs)
            ),
            map((productsOCs) => productsOCs.filter(product => product)),
            mergeMap((productsOCs) => this._preoloadPromotion(productsOCs).pipe(map(() => productsOCs))),
            mergeMap(productsOCs => forkJoin(productsOCs.map(this._getFaiBundle))),
            catchError(()=>{
                this.loadingError = true;
                return throwError(() => new Error('Aucune offre n\'a été trouvée pour cette catégorie.'));
            })
        ).subscribe(
            (faiBundles: IFaiBundle[]) => {
                this._bundlesBacked = faiBundles;
                this._getAvailablePartners();
                this._assignFilteredOffers();
                this._filterEquipments();
                this.scoringRules = this.offerKeys.length && this.scoringService.getScoringError(
                    this.offers[this.offerKeys[0]][0].fai
                );
                this._selectRecoveryOffer();
            }
        );

        this.filterOffersByType.valueChanges.subscribe(() => this._assignFilteredOffers());
    }

    public getNameOfKey(key: string): string {
        return key
            .replace('/box_4g/gi', 'Box 4G')
            .replace('/box_5g/gi', 'Box 5G')
            .replace(new RegExp('_undefined', 'gi'), '')
            .replace('_exclu_asso', ' (Exclu asso)')
            .replace('_smart_tv', ' (Smart Tv)')
            .replace('/3P/gi', 'TV - Téléphonie fixe - Internet')
            .replace('/2P/gi', 'Téléphonie fixe - Internet')
            .replace('_', ' ');
    }

    public toNumber(value?: string): number {
        return (value) ? parseFloat(value) : 0;
    }

    public toggleOffer(offer: string): void {
        this.selectedOffer = offer === this.selectedOffer ? null : offer;
    }

    public trackByFn(index: number, item: IFaiBundle): string {
        return item.fai.gencode;
    }

    public back(): void {
        this.router.navigateByUrl(FAI_ROUTES.TECHNO);
    }

    private _selectRecoveryOffer(): void {
        if (this.orderRecovery) {
            this.toggleOffer(this.orderRecovery?.fai?.offer?.id);
        }
    }

    private _assignFilteredOffers(): void {
        const filteredOffers: IFaiOffers = this._getFiltredOffer(this._bundlesBacked);
        const filteredByRange: IFaiOffers = this._filterByOfferRange(filteredOffers);
        const filteredOffersTotal: number = Object.keys(filteredByRange).length;
        this.offers = filteredOffersTotal ? filteredByRange : null;
        this.offerKeys = filteredOffersTotal ? this._getOrderOffersParams(this.offers) : [];
    }

    private _filterTechno(technos: string[]): (offer: Fai) => boolean {
        return (fai): boolean => technos.length === 0 || technos.includes(fai?.technology?.toLowerCase() || 'box_4g');
    }

    private _filterFaimInStock(): (offer: Fai) => boolean {
        return (offer): boolean =>
            !ProductFactory.Is(offer, FaimUnlimited) || (ProductFactory.Is(offer, FaimUnlimited) && offer.data.in_stock);
    }

    private _filterEquipments(): void {
        this.filterEquipements = {all: EQUIPMENT_FILTER_LABELS.all};
        Object.values(this._bundlesBacked).forEach(o => {
            const oEquip: string = o.fai.equipment;
            if (oEquip && EQUIPMENT_FILTER_LABELS[oEquip]) {
                this.filterEquipements[oEquip] = EQUIPMENT_FILTER_LABELS[oEquip];
            }
        });
    }

    private _filterByOfferRange(offers: ObjectOf<IFaiBundle[]>): ObjectOf<IFaiBundle[]> {
        const smartTvRangesLines: string[] = [
            'bbox_tv_plus',
            'bbox_smart_tv',
            'bbox_smart_tv_large',
            'sl_large_smart_tv'
        ];
        const smartTvOffers: ObjectOf<IFaiBundle[]> = {};

        Object.keys(offers).forEach(
            (key: string) => {
                const filteredSmartTvOffers: IFaiBundle[] = offers[key].filter(offer => smartTvRangesLines.includes(offer?.fai?.range));
                if (filteredSmartTvOffers.length) {
                    offers[key] = offers[key].filter(offer => !filteredSmartTvOffers.map(o => o.fai.gencode).includes(offer.fai.gencode));
                    const offerKey: string = `${key}_smart_tv`;
                    const accSmartTvOffersOffers: IFaiBundle[] = smartTvOffers[offerKey]?.length ? smartTvOffers[offerKey] : [];
                    smartTvOffers[offerKey] = [...accSmartTvOffersOffers, ...filteredSmartTvOffers];
                }

                const assoOffers: IFaiBundle[] = offers[key].filter(offer => offer.fai.data.exclu_assos === true);
                if (assoOffers.length) {
                    offers[key] = offers[key].filter(offer => !assoOffers.map(o => o.fai.gencode).includes(offer.fai.gencode));
                    const offerKey: string = `${key}_exclu_asso`;
                    const accAssoOffers: IFaiBundle[] = smartTvOffers[offerKey]?.length ? smartTvOffers[offerKey] : [];
                    smartTvOffers[offerKey] = [...accAssoOffers, ...assoOffers];
                }

                if (!offers[key].length) {
                    delete offers[key];
                }
            }
        );
        return {...smartTvOffers, ...offers};
    }

    private _getFiltredOffer(bundles: IFaiBundle[]): IFaiOffers {
        return this._groupeBy()(
            this._deduplicateBundle()(
                bundles.filter(this._filterOffer())
            )
        );
    }

    private _filterOffer(): (offer: IFaiBundle) => boolean {
        return (offer): boolean =>
            this._filterOfferByType(this.filterOffersByType.get('offerType').value)(offer) &&
            this._filterOfferByObligation(this.filterOffersByType.get('sortByObligation').value)(offer) &&
            this._filterOfferByEquipement(this.filterOffersByType.get('sortByEquipmentType').value)(offer) &&
            this._filterOfferByPartner(this.filterOffersByType.get('sortByPartner').value)(offer);
    }

    private _filterOfferByEquipement(val: string): (offer: IFaiBundle) => boolean {
        return (o: IFaiBundle): boolean => val === 'all' || o?.fai?.data.equipment === val;
    }

    private _filterOfferByObligation(val: string): (offer: IFaiBundle) => boolean {
        return (o: IFaiBundle): boolean => val === 'all' || o?.fai?.data?.obligation?.includes(val);
    }

    private _filterOfferByPartner(val: string): (offer: IFaiBundle) => boolean {
        return (o: IFaiBundle): boolean => o?.fai?.commercial_partner.includes(val);
    }

    private _filterOfferByType(val: string): (offer: IFaiBundle) => boolean {
        switch (val) {
            case this.proOfferLabel:
                return (o: IFaiBundle): boolean =>
                    !o?.fai?.data.fai_category_type.includes('offre_special') &&
                    !o?.fai?.data.fai_category_type.includes('offre_old');
            case 'offre_special':
                return (o: IFaiBundle): boolean => !!o.skus.find((sku) => this._offerWithManualPromotion.includes(sku) &&
                    !o?.fai?.data.fai_category_type.includes('offre_old')) ||
                    o?.fai?.data.fai_category_type.includes('offre_special');
            case 'offre_historique':
                return (o: IFaiBundle): boolean => o?.fai?.data.fai_category_type.includes('offre_old');
            case 'offre_gp':
                return (o: IFaiBundle): boolean => o?.fai?.data.fai_category_type.includes('offre_gp');
            case 'all':
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                return (o: IFaiBundle): boolean => true;
        }
    }

    private _getOrderOffersParams(groupe: ObjectOf<IFaiBundle[]>): string[] {
        const offersKeys = Object.keys(groupe);
        const orderTechno: string[] = [
            'FTTH_3P',
            'FTTH_2P_smart_tv',
            'FTTH_2P',
            'FTTH_2P_exclu_asso',
            'VDSL_3P',
            'VDSL_2P',
            'VDSL_2P_exclu_asso',
            'ADSL_3P',
            'ADSL_2P',
            'ADSL_2P_exclu_asso',
            'BOX_5G_undefined',
            'BOX_4G_undefined',
            'THD_3P'
        ];
        return offersKeys.sort((a, b) =>
            orderTechno.indexOf(a) - orderTechno.indexOf(b)
        );
    }

    private _getAvailablePartners(): void {
        this.sortByPartners.push({ label: 'Aucun', key: 'none' });
        this._bundlesBacked.map(p => p.fai).forEach((p) => {
            const filterExist = this.sortByPartners.some(filter => filter.key === p?.commercial_partner);
            if (p?.commercial_partner && !filterExist) {
                this.sortByPartners.push({ label: p.commercial_partner, key: p.commercial_partner });
            }
        });
    }

    private _preoloadPromotion(products: Fai[]): Observable<void> {
        const cart = this.canAddFaiInQuoteMixed ? this.cartTelesalesService.cartModel : new CartModel();
        if (this.canAddFaiInQuoteMixed) {
            this.cartTelesalesService.clearQuoteFai();
        }
        this.cartService.addQuote(cart,new QuoteContextFaiModel());

        const indexQuote = this.cartTelesalesService.getQuoteIndexByContext(QuoteContextFaiModel);
        return this.promotionService.preloadPromotions(cart, products.map((p) => p.gencode), indexQuote).pipe(tap((promotions) => {
            this._offerWithManualPromotion = Object.values(promotions).reduce((acc, promotion) => [
                ...acc,
                ...promotion.manual.reduce((accM, p) => [
                    ...accM,
                    ...p.relevantProducts
                ], [])
            ], []);
        }), tap((promotions) => {
            this._offerWithManualPromotion.forEach(offer => {
                promotions[offer].manual.forEach(item => {
                    if (item?.typesMarketing?.includes(TYPE_MARKETING.CODE_POSTAL_ELIGIBILITE_FIXE)) {
                        if (!this.offerWithManualPromotionZipCode.includes(offer)) {
                            this.offerWithManualPromotionZipCode.push(offer);
                        }
                    }
                });
            });
        }), map(() => null));
    }

    @bind
    private _getFaiBundle(ocProduct: Fai): Observable<IFaiBundle> {
        return this.cartService.generateCart([ocProduct], false, new QuoteContextFaiModel())
            .pipe(map(cart => ({
                fai: cart.getQuote().getProductByType(Fai),
                installation: cart.getQuote().getProductByType(Installation),
                activation: cart.getQuote().getProductByType(Activation),
                location: cart.getQuote().getProductByType(FaiBox),
                skus: [cart.getQuote().getProductByType(Fai)?.data?.gencode],
                cart
            })));
    }

    @bind
    private _groupeBy(keys: string[] = [
        'fai.data.technology',
        'fai.data.play',
    ]): (tab: IFaiBundle[]) => ObjectOf<IFaiBundle[]> {
        return (tab: IFaiBundle[]): ObjectOf<IFaiBundle[]> => {
            const groupe: { [index: string]: IFaiBundle[] } = {};
            for (const faiBundle of tab) {
                let newKey: string = '';
                for (const val of keys) {
                    if (newKey !== '') {
                        newKey += '_';
                    }
                    newKey += GetDeepValue(faiBundle, val);
                }
                if (!groupe[newKey]) {
                    groupe[newKey] = [];
                }
                groupe[newKey].push(Object.assign({}, faiBundle));
            }
            return groupe;
        };
    }

    @bind
    private _deduplicateBundle(fusionKeys: string[] = [
        'fai.data.equipment',
        'fai.data.range',
        'fai.prices.final',
        'location.prices.final',
        'fai.data.obligation',
        'fai.data.technology',
        'fai.data.play'
    ]): (faiBundles: IFaiBundle[]) => IFaiBundle[] {
        return (faiBundles: IFaiBundle[]): IFaiBundle[] => Object.values(this._groupeBy(fusionKeys)(faiBundles))
            .reduce((acc, val) => {
                acc.push({ ...val[0], skus: val.map((bundle) => bundle.fai.data.gencode) });
                return acc;
            }, []);
    }

}
