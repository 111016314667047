import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ProductRepository } from '@repositories/product.repository';
import { IProductRepositoryInterface, Product, ProductFactory } from '@bytel/bytel-sales';
import { CategoryModel, CategoryWithProductModel } from '@models/category.model';
import { EquipmentDetailsModel } from '@models/equipment-details.model';
import { ProductDetailsRepository } from '@repositories/product-details.repository';
import bind from '../helper/decorators/bind';
import {
    IContextualizedProductsParams,
    ISapicConnectivity, ISapicFinancementProduit,
    ISapicProduct,
    ITYPE_MARKETING
} from '@interfaces/products.interface';
import { TYPE_FINANCEMENT } from '@interfaces/funding.interface';

@Injectable({
    providedIn: 'root'
})
export class CatalogService  implements IProductRepositoryInterface {

    private readonly PRELOAD_CATEGORIES: string[] = [];

    constructor(
        private productRepository: ProductRepository,
        private productDetailsRepository: ProductDetailsRepository
    ) {
        this.getCategoriesAndChilds(this.PRELOAD_CATEGORIES,true).subscribe();
    }

    public getProductByGencode<T extends Product>(gencode: string): Observable<T> {
        return this.getProductByAttribute(gencode,'gencode');
    }

    public resolve(): Observable<boolean> {
        return this.productRepository.getProducts().pipe(map(()=>true));
    }

    public getProductCategories(categoriesCode: string[] = []): Observable<CategoryModel[]> {
        return this.productRepository
            .getProductCategories()
            .pipe(map((categories)=>categories.filter(data => categoriesCode.includes(data.code))));
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public getCategoriesAndChilds<T extends Product = Product>(categoriesCode: string[],
        withProducts: false): Observable<CategoryModel[]>;
    public getCategoriesAndChilds<T extends Product = Product>(categoriesCode: string[],
        withProducts: true): Observable<CategoryWithProductModel<T>[]>;
    public getCategoriesAndChilds<T extends Product = Product>(
        categoriesCode: string[],
        withProducts: boolean
    ): Observable<CategoryWithProductModel<T>[]|CategoryModel[]> {
        let $obs: Observable<CategoryModel[]|CategoryWithProductModel<T>[]> =  this.getProductCategories(categoriesCode);
        if (withProducts){
            $obs = $obs.pipe(mergeMap((data: CategoryModel[])=>this.getCategoriesWithProducts<T>(data)));
        }
        return $obs;
    }

    @bind
    public getCategorieWithProducts<T extends Product = Product>(categorie: CategoryModel): Observable<CategoryWithProductModel<T>>{
        return forkJoin({
            products: this.productRepository.getProductsByAttribute<T>(categorie.products, 'gencode'),
            subCat: this.getCategoriesWithProducts<T>(categorie.children)
        }).pipe(map(({products,subCat})=>
            new CategoryWithProductModel<T>(categorie,products,subCat)
        ));
    }

    @bind
    public getCategoriesWithProducts<T extends Product = Product>(categories: CategoryModel[]): Observable<CategoryWithProductModel<T>[]>{
        return categories.length === 0 ?
            of([]) :
            forkJoin(categories.map((data)=>this.getCategorieWithProducts<T>(data)));
    }

    public getProductByAttribute<T extends Product>(value: string, attribute?: string): Observable<T> {
        return this.productRepository.getProductsByAttribute([value],attribute)
            .pipe(map(products=>products.shift() as T));
    }

    public getProductsByGencodes<T extends Product>(gencodes: string[]): Observable<T[]> {
        return this.productRepository.getProductsByAttribute(gencodes,'gencode');
    }

    public getProductDetails<T extends Product>(gencode: string): Observable<Product|EquipmentDetailsModel> {
        return this.productDetailsRepository.getProductWithDetails<T>(gencode);
    }

    public getContextualizedProducts(paramsQuery: {
        [index: string]: string | number;
    }, data: IContextualizedProductsParams): Observable<{
            products: Product[];
            raw: ISapicProduct[];
            totalCount: number;
        }> {
        return this.productRepository.getContextualizedProducts(paramsQuery, data);
    }

    public getContextualizedProductsOrigin(paramsQuery: {
        [index: string]: string | number;
    }, data: IContextualizedProductsParams): Observable<{
            produits: ISapicProduct[];
            nombreTotalProduits: number;
        }> {
        return this.productRepository.getContextualizedProductsOrigin(paramsQuery, data);
    }

    public getDuration(plan: ISapicProduct): number {
        const promotion = plan.promotions.filter(
            (promo) =>
                !promo.estIncitation && !promo.estDifferee
                && !promo?.proprietesSupplementaires?.typesMarketing?.includes(ITYPE_MARKETING.CONVERGENCE)
                && promo.reduction === plan.prix.pourToujours - plan.prix.final);

        if (promotion.length > 0) {
            return promotion[0].duree;
        }
        return 0;
    }

    public getFinalPricePhone(plan: ISapicProduct, withEdp: boolean): ISapicFinancementProduit {
        let typeFinancement = 'COMPTANT';
        if (withEdp) {
            typeFinancement = 'EDP';
        }
        let financement = plan.panierSimule.financements.filter((modeFinancement) => modeFinancement.type === typeFinancement);
        if (financement.length === 0) {
            financement = plan.panierSimule.financements.filter((modeFinancement) => modeFinancement.type !== typeFinancement);
        }
        return financement[0];
    }

    public mapSapicToJsonProduct<T extends Product>(product: ISapicProduct): T{
        const funding = product.financements.find(f=>f.prefere && f.type === TYPE_FINANCEMENT.EDP);
        const colors = product?.couleurs?.includes(null) ? [''] : (product?.couleurs );
        const connectivityMatcher = {
            [ISapicConnectivity['5G']]: '5g',
            [ISapicConnectivity['4G']]: '4g',
            [ISapicConnectivity['4G+']]: '4g+',
        };
        const connectivity = product.connectivite ? connectivityMatcher[product.connectivite] : undefined;

        const productJson = {
            name: product.nom,
            gencode: product.gencode,
            entity_id: 0,
            product_ids: product.enfants,
            type_id: product.type,
            exclu_pro: product.excluPro,
            battery_removable: product.aBatterieAmovible,
            battery_capacity: product.capaciteBatterie,
            categories: product.categories,
            max_connection: connectivity,
            color: colors,
            sim_type: product.typeSim,
            sar: product.dasTete,
            trunk_sar: product.dasTronc,
            limb_sar: product.dasMembre,
            anim_co: product.etiquetteAnimCo,
            manufacturer: product.marque,
            note_reparation: product.indiceReparabilite,
            price: product.prix.initial,
            final_price: funding?.coutTotalFinancement ?? product.prix.final,
            prices: null,
            image: product.image,
            small_image: product.image,
            imageHD: product.image,
            medias: product.medias,
            url: product.url,
            in_stock: product.quantite > 0,
            max_qty: 1, // Set by product factory from each type
            ...(funding ? {
                edp_phone: {
                    amount: funding.montantMensualite,
                    duration: funding.nbrEcheances,
                    price: funding.montantAFinancer
                }
            } : {}),
        } as any;

        if (productJson.gencode === ProductRepository.OPTION_PRO_GENCODE){
            productJson.exclu_pro = true;
        }
        if (productJson.gencode === ProductRepository.FMS_EDP){
            productJson.type_id = 'edpFms';
        }
        if (productJson.hasOwnProperty('first_page')){
            productJson.firstPage = productJson.first_page;
        }
        if (productJson.hasOwnProperty('sim_type')) {
            productJson.is_esim_compatible = ['hybride', 'esim'].includes(productJson?.sim_type);
        }
        if (productJson?.type_id === 'qr_esim') {
            productJson.isESim = true;
        }
        return ProductFactory.GetInstance<T>(productJson);

    }

}
