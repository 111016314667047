<div class="card is-vcentered is-fullheight" [ngClass]="{'bullet-item': isBullet, 'is-horizontal' : mainOption.length === 1}" [data-cy]="isBullet ? 'option-bullet' : 'option-first-page'">
    <div class="card-image" [ngClass]="{'is-2': mainOption.length > 1, 'is-4': mainOption.length === 1}">
        <figure class="image">
            <img tlvImageLoading [btlSrc]="option.image" [btlSrcSet]="option.image" alt="{{option.name}}">
        </figure>
    </div>
    <div class="card-content has-text-centered">
        <h4 [ngClass]="{'title is-level-2': isBullet,'is-size-5 is-size-3-desktop has-text-weight-semibold':!isBullet }">{{option.name}}</h4>
        <div *ngIf="isBullet;else description" [innerHTML]="option.data.bullet_description | trustHtml"></div>
        <ng-template #description>
            <p class="is-size-4 is-invisible">{{option.data.bullet_description}}</p>
        </ng-template>
        <div *ngIf="isBullet" class="divider is-invisible"></div>
        <p *ngIf="option.data.wording_promo" class="has-text-primary is-size-5">{{option.data.wording_promo}}</p>
        <div class="is-inline-block has-text-centered">
            <tlv-price [hasPeriod]="true" [price]="option.prices.final" size-3></tlv-price>
            <div class="is-divider has-background-white"></div>
            <ng-container *ngIf="!qty">
                <button class="button is-primary" (click)="addProduct()" [disabled]="!option.available" [data-cy]="'item-btn-add-'+option.gencode">
                    <span>Ajouter</span>
                </button>
            </ng-container>
            <ng-container *ngIf="qty">
                <button class="is-tertiary button" (click)="removeProduct()" [data-cy]="'item-btn-remove-'+option.gencode">
                    <span>Supprimer</span>
                </button>
            </ng-container>
        </div>
    </div>
</div>