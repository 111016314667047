import {
    Resultat
} from '@bytel/pt-ihm-api-egide-controle-risque-vente-demander-offres-autorisees/dist/models/components/schemas/Resultat';

export interface IGlobalScoring {
    incompatibilitySalesRisk?: {
        basic?: Resultat;
        premium?: Resultat;
        faim?: Resultat;
        fai?: Resultat;
        edp?: Resultat;
    };
}

export enum ISCORING_ACTIONS {
    LINK = 'lien',
    EDP = 'supprimerEdp',
    ENDCALL = 'terminerAppel'
}
