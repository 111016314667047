import { Injectable } from '@angular/core';
import { ResponseSkeepers } from '@interfaces/skeepers-interface';
import { SkeepersRepository } from '@repositories/skeepers.repository';

@Injectable({
    providedIn: 'root'
})
export class SkeepersService {
    public listRatingSkeepers: ResponseSkeepers = {};

    constructor(
        private skeepersRepository: SkeepersRepository
    ) {}

    public resolve(): void {
        this._initSkeepersRate();
    }

    public getRates(): ResponseSkeepers {
        return this._initSkeepersRate();
    }

    private _initSkeepersRate(): ResponseSkeepers {
        if (this.listRatingSkeepers && Object.keys(this.listRatingSkeepers).length === 0) {
            this.skeepersRepository.getSkeepersRate().subscribe(
                (data: ResponseSkeepers) => {
                    this.listRatingSkeepers = data;
                }
            );
        }
        return this.listRatingSkeepers;
    }

}


