import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { SaleForceInfosModel } from '@models/sale-force-infos.model';
import { ISalesForce } from '@interfaces/salesforce.interface';
import { AddressModel } from '@models/cart/address.model';
import { USER_TITLES } from '../constants/customer';
import { SalesforceStorage } from '@repositories/storages/salesforce.storage';
import { classToPlain, plainToClass } from 'class-transformer';
import { CustomerSearchModel } from '@models/customer/customer-search.model';
import { CustomerProSearchModel } from '@models/customer/customer-pro-search.model';

@Injectable({
    providedIn: 'root'
})
export class SalesForceRepository{

    constructor(private router: Router) {}

    // Load By Guard
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public loadUrlParam(): Observable<boolean> {
        const dataFromStorage = SalesforceStorage.getItem(SalesforceStorage.KEYS.DATA);
        if (dataFromStorage){
            return of(true);
        }
        const urlData = this.router.getCurrentNavigation().extractedUrl.toString()?.split('?')[1];
        if (!urlData){ return of(false); }
        let data: ISalesForce = urlData.split('&').reduce((acc,val)=>{
            const keyVal = val.split('=');
            return {...acc,[keyVal[0]]:keyVal[1]};
        },{}) as ISalesForce;
        data = Object.keys(data).reduce((acc,key)=>({...acc,[key.toUpperCase()]:decodeURIComponent(data[key])}),{} as ISalesForce);
        const civility: USER_TITLES = (data.LEAD_SALUTATION === 'M.' ? USER_TITLES.mister : USER_TITLES.missis);
        const customer: Partial<CustomerSearchModel|CustomerProSearchModel> = {
            email: data.LEAD_EMAIL,
            firstname: data.LEAD_FIRSTNAME,
            lastname: data.LEAD_LASTNAME,
            phone: data.LEAD_MOBILE,
            socialReason: data.LEAD_RAISONSOCIALE,
            gender: data.LEAD_SALUTATION ? civility : null,
            idPerson: data.PU_ID
        };
        const isInCallType: boolean = data.TYPEAPPEL?.toLowerCase() === 'entrante';
        const callType: string = (isInCallType ? 'in' : 'out');
        const saleForceInfosData: Partial<SaleForceInfosModel> = {
            ...(data.LEAD_CODEPOSTAL ? {address: new AddressModel({
                postalCode: data.LEAD_CODEPOSTAL?.toString(),
                city: data.LEAD_VILLE,
                street: data.LEAD_NOMDEVOIE,
                streetNumber: data.LEAD_NUMDEVOIE?.toString(),
                complement: data.LEAD_COMPVOIE
            })} : {}),
            customer : Object.values(customer).find(val=>!!val) ?
                data.LEAD_PRO ? new CustomerProSearchModel(customer) : new CustomerSearchModel(customer) :
                null,
            idProspect: data.PRM_ID,
            orderId: data.COMMANDEID,
            sourceProspect: data.SOURCEPROSPECT,
            idTemporaireProspect: data.LEAD_ID,
            salesType: data.TYPE_VENTE,
            idDadCase: data.CASEOPPORTUNITE,
            idOpportunity: data.IDOPPORTUNITE,
            callType: data.TYPEAPPEL ? callType : null,
            compagneName: isInCallType ?  data.PROVENANCEAPPEL?.replace(/_/g,' ') : data.CODECAMPAGNE?.replace(/_/g,' '),
            compagneCode: data.CODECAMPAGNE
        };
        if (Object.values(saleForceInfosData).find((val)=>!!val)){
            this.save(new SaleForceInfosModel(saleForceInfosData));
            return of(true);
        }
        return of(false);
    }

    public save(data: SaleForceInfosModel): void{
        SalesforceStorage.setItem(SalesforceStorage.KEYS.DATA,classToPlain(data));
    }

    public getData(): SaleForceInfosModel{
        const dataFromStorage = SalesforceStorage.getItem(SalesforceStorage.KEYS.DATA);
        return dataFromStorage ? plainToClass(SaleForceInfosModel,dataFromStorage) : new SaleForceInfosModel({});
    }

    private _cleanUrlData(url: string): string {
        if (url === 'nul') {return null;}
        return url.replace(/%20/gi, ' ')
            .replace(/%C3%88/gi, 'È')
            .replace(/%22/gi, '')
            .replace(/\+/g, ' ')
            .replace('\"', '');
    }

}
