import { Injectable } from '@angular/core';
import { CartModel, PromotionModel, PromotionsModel } from '@bytel/bytel-sales';
import { DisplayAccessoryModel } from '@models/cart/display-accessory.model';
import { PromotionRepository } from '@repositories/promotion.repository';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class PromotionsService {

    constructor(private promotionRepo: PromotionRepository) {}

    public getCouponPromotionForCart(cart: CartModel, code: string): Observable<PromotionModel>{
        return this.promotionRepo.getCouponPromotionForCart(cart, code);
    }


    public getAllCouponPromotionForCart(cart: CartModel): Observable<string[]>{
        return this.promotionRepo.getAllCouponPromotionForCart(cart);
    }

    public getPromotions(cart: CartModel): Observable<PromotionsModel>{
        return this.promotionRepo.getPromotions(cart);
    }

    public preloadPromotions(cart: CartModel, gencode: string[], indexQuote?: number): Observable<{[index: string]: PromotionsModel}>{
        return this.promotionRepo.preload(cart, gencode, indexQuote);
    }

    public getPromotionsCart(cart: CartModel): Observable<PromotionsModel>{
        return this.promotionRepo.getPromotionsForCart(cart);
    }

    public GetPromotionsForProduct(product: DisplayAccessoryModel, promotions: PromotionsModel): PromotionsModel {
        const couponPromo: PromotionModel = promotions.coupon;
        return new PromotionsModel(
            promotions.automatic.filter((p) => this.isApplicable(product, p)),
            promotions.manual.filter((p) => this.isApplicable(product, p)),
            promotions.incitation.filter((p) => this.isApplicable(product, p)),
            (couponPromo && this.isApplicable(product, couponPromo)) ? couponPromo : null
        );
    }

    public isApplicable(product: DisplayAccessoryModel,promotion: PromotionModel): boolean{
        return promotion.relevantProducts.includes(product.gencode);
    }
}
