import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
    Equipment,
    Fai,
    IFai as FaiInterface,
    Insurance,
    IPlan,
    Phone,
    Plan,
    Product,
    ProductFactory,
    QuoteContextAcquisitionModel,
    QuoteModel,
    Sensation,
    Sowo
} from '@bytel/bytel-sales';
import { QuoteMixedModalComponent } from '@components/checkout/step/cart/quote-mixed-modal/quote-mixed-modal.component';
import { MainCartModel } from '@models/cart/main-cart.model';
import { DialogRef, DialogService } from '@ngneat/dialog';
import { CatalogService } from '@services/catalog.service';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { ScoringService } from '@services/checkout/scoring.service';
import { StepperService } from '@services/checkout/stepper.service';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import bind from '../../../../helper/decorators/bind';
import { BasicCrosssellsComponent } from './basic-crosssells/basic-crosssells.component';
import { CartStep } from './cart.step';
import { MobileRecoveryService } from '@services/mobile-recovery.service';
import { ALLOWED_CATEGORIES } from '@interfaces/api/catalog.interface';
import {FaiEligibilityService} from '@services/fai/fai-eligibility.service';
import { SalesUserService } from '@services/sales-user.service';
import { OpportunityService } from '@services/opportunity.service';
import { QualificationService } from '@services/qualification.service';
import {OrderModel} from '@models/order/order.model';
import {SalesForceService} from '@services/salesforce.service';
import {
    Resultat
} from '@bytel/pt-ihm-api-egide-controle-risque-vente-demander-offres-autorisees/dist/models/components/schemas/Resultat';

@Component({
    selector: 'tlv-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss']
})
export class CartComponent implements AfterViewChecked, AfterViewInit, OnDestroy {
    @Output() FormValidationStatus: EventEmitter<boolean> = new EventEmitter();

    public isBareCart: boolean = false;
    public isCartEmpty: boolean = true;
    public plan: Plan;
    public equipment: Equipment;
    public isSas: boolean = false;
    public isFai: boolean = false;
    public isSimo: boolean = false;
    public hasFai: boolean = false;
    public hasSimo: boolean = false;
    public isQuoteMixed: boolean = false;
    public productLoaded: boolean;
    public insurance: Product;
    public quoteIndex: number;
    public crosssellTypes = BasicCrosssellsComponent.CROSSSELL_TYPES;
    public cartForm: FormGroup = new FormGroup({
        _blank : new FormControl<boolean | null>(null, []),
        portability: new FormControl<boolean | null>(null, [Validators.required]),
        parentalControl: new FormControl<boolean | null>(null, [Validators.required]),
        insuranceControl: new FormControl<boolean | null>(null, [Validators.required]),
        simChoice: new FormControl<boolean | null>(null, [Validators.required])
    });

    // prop old parent component
    public isSubmitted: boolean;
    public isPreorder: boolean;
    public isLoading: boolean;
    public quote: QuoteModel;
    public orderRecovery: OrderModel;
    public scoringRules: Resultat;

    private _subscriptions: Subscription[] = [];

    constructor(
        private stepperService: StepperService,
        private cartService: CartTeleSalesService,
        private cdr: ChangeDetectorRef,
        private mobileRecoveryService: MobileRecoveryService,
        private cartStep: CartStep,
        private readonly catalogService: CatalogService,
        private router: Router,
        private dialogService: DialogService,
        private route: ActivatedRoute,
        private readonly scoringService: ScoringService,
        private faiEligibilityService: FaiEligibilityService,
        private qualificationService: QualificationService,
        private salesUserService: SalesUserService,
        private opportunityService: OpportunityService,
        private salesForceService: SalesForceService,
    ) {
        if (!this.cartService.cartModel.getQuote()?.getProductByType(Phone)){
            this.mobileRecoveryService.resetMobileRecovery();
        }
        this.orderRecovery = this.salesForceService.prefilledInfo?.order;
        this.cartService.refreshObs.subscribe(this._loadCartProducts);
        this._subscriptions.push(this.cartForm.statusChanges.subscribe(() => {
            this.cartStep.canBeValidate = this.cartForm.valid;
            this.stepperService.refresh();
        }));
    }

    public ngAfterViewInit(): void {
        this.faiEligibilityService.checkDuplicateOrder(null);
        this.cartStep.canBeValidate = this.cartForm.valid;
        this.stepperService.refresh();
    }

    public ngAfterViewChecked(): void {
        if (this.productLoaded === false) {
            this.productLoaded = true;
        }
        this.cdr.detectChanges();
    }

    public validateStep(): void { // Change name
        if (!this.cartForm.valid) {
            throw new Error('Merci de renseigner toutes les informations');
        }
        this.isSubmitted = true;
        this.isLoading = this.stepperService.goToNextStep();
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach(sub => sub?.unsubscribe());
    }

    public checkDadThenGoToFai(): void {
        if (!this.qualificationService.isDadCampaign()) {
            this.goToFai();
            return;
        }
        const dialogRef: DialogRef<any> = this.opportunityService.openOpportunityDadModal();
        dialogRef.afterClosed$.subscribe((results: { [key: string]: any }) => {
            if (!results?.continue) {
                this.salesUserService.closeCall();
            } else {
                this.goToFai();
            }
        });
    }


    public goToFai(): void{
        const index = this.cartService.getQuoteIndexByContext(QuoteContextAcquisitionModel);
        const phone = this.cartService.cartModel.getQuote(index)?.getProductByType(Phone);
        if (index === null || !phone) {
            this.router.navigate(['fai']);
            return;
        }
        const refDialog = this.dialogService.open(
            QuoteMixedModalComponent,
            {data:
                    {
                        isSimo:false,
                        accessory: false
                    },closeButton: false, enableClose: false}
        );
        this._subscriptions.push(refDialog.afterClosed$.subscribe((skip) => {
            if (skip){
                if (index !== null) {
                    this.cartService.removeProductsByGencode(phone.gencode).subscribe();
                }
                this.router.navigate(['fai']);
            }
        }));
    }

    @bind
    private _loadCartProducts(cartModel: MainCartModel): void {
        const context = this.route.snapshot.data.context;
        let index = cartModel.currentQuoteIndex = 0;
        if (context) {
            index = this.cartService.getQuoteIndexByContext(context) ?? cartModel.currentQuoteIndex ;
            cartModel.currentQuoteIndex = index;
            this.cartService.cartModel.currentQuoteIndex = index;
        }
        this.quoteIndex = index;
        this.quote = cartModel.getQuote(index);
        this.hasFai = !!this.cartService.hasFaiInCart();
        this.hasSimo = !!this.cartService.hasSimoInCart();
        this.isCartEmpty = cartModel.isCartEmpty();

        if (!this.quote || this.quote?.products?.length === 0) {
            if (context){
                this.router.navigate(['panier']);
            }
            this.quote = this.cartService.getQuoteHasProduct() ?? this.quote;
        }

        this.plan = this.quote.getPrincipalProduct<Plan>('Plan');
        this.equipment = this.quote.getPrincipalProduct<Equipment>('Equipment');
        this._checkInsurance();

        this.isPreorder = this.quote.isPreorder();
        this.isSas = ProductFactory.Is(this.plan, Sensation, true);
        this.isFai = ProductFactory.Is(this.plan, Fai, true);
        this.isSimo = ProductFactory.Is(this.plan, Sowo);

        if (this.isFai || this.isSimo) {
            this.catalogService.getCategoriesAndChilds<Product>([ALLOWED_CATEGORIES.SIMOFAI_TELESALES], true)
                .pipe(map(data => this.isQuoteMixed = !!data[0]?.products.find(p => (p.gencode === this.plan.gencode))))
                .subscribe(
                    () => {
                        if (this.isQuoteMixed && this.isSimo && !this.hasFai) {
                            this.scoringRules = this.scoringService.getScoringError(new Fai({} as FaiInterface));
                        } else if (this.isQuoteMixed && this.isFai && !this.hasSimo) {
                            this.scoringRules = this.scoringService.getScoringError(new Sowo({} as IPlan));
                        }

                    }
                );
        }
        this.isCartEmpty = cartModel.isCartEmpty();

        this.isBareCart = this._isCartBareEmpty();
        this.productLoaded = false;
        this._loadCartForm();
    }

    private _checkInsurance(): void {
        if (this.equipment?.related) {
            this.catalogService.getProductsByGencodes(this.equipment.related)
                .pipe(
                    map((products) => products.filter(product => ProductFactory.Is(product, Insurance)))
                )
                .subscribe(
                    (data) => this.insurance = data.pop()
                );
        }
    }

    private _isCartBareEmpty(): boolean {
        return !this.cartService.cartModel.isCartEmpty() && !this.equipment && !this.plan;
    }

    private _loadCartForm(): void {
        if (this.plan) {
            this.cartForm.get('parentalControl').enable();
        } else {
            this.cartForm.get('parentalControl').disable();
        }
        if (this.plan?.hasPorta && !ProductFactory.Is(this.plan, Fai)){
            this.cartForm.get('portability').enable();
        } else {
            this.cartForm.get('portability').disable();
        }
        if (this.insurance){
            this.cartForm.get('insuranceControl').enable();
        } else {
            this.cartForm.get('insuranceControl').disable();
        }
        if (this.plan && !this.isFai) {
            this.cartForm.get('simChoice').enable();
        } else {
            this.cartForm.get('simChoice').disable();
        }
    }

}
