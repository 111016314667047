<div class="box" [formGroup]="simChoiceForm">
    <div class="box-content has-text-left">
        <div class="columns is-vcentered is-multiline" [class.is-centered]="isLoading" data-cy="block-sim-choice">
            <div class="column" *ngIf="hasError">
                <div class="resume-notification notification is-small has-body is-danger is-marginless">
                    <div class="icon has-text-tertiary">
                        <i class="tri-exclamation-circle"></i>
                    </div>
                    <div class="body">
                        <p class="text is-1">Une erreur est survenue lors du chargement des sims disponibles. Merci de terminer l'appel.</p>
                    </div>
                </div>
            </div>
            <div class="colmun" *ngIf="isLoading">
                <tlv-loader iconSize="medium" isAnimated="true" loadingText="Chargement des sims disponibles..."></tlv-loader>
            </div>
            <ng-container *ngIf="!isLoading && !hasError">
                <div class="column" [class.is-8]="displayableSims.length > 1">
                    <div class="is-size-4 has-text-weight-semibold icon-and-text">
                        <span *ngIf="displayableSims.length === 1">SIM incluse</span>
                        <span *ngIf="displayableSims.length > 1">Je choisis ma SIM</span>
                    </div>
                </div>
                <div class="column" [ngClass]="{'is-4': displayableSims.length > 1, 'is-narrow': displayableSims.length === 1}">
                    <div class="options buttons is-centered" [class.is-centered]="displayableSims.length > 1" data-cy="sim-cards-choice">
                        <ng-container *ngFor="let sim of displayableSims">
                            <div class="field" [class.single-sim-choice]="displayableSims.length === 1">
                                <div class="control has-text-centered">
                                    <input type="radio" formControlName="simSelection" [value]="sim.gencode" [id]="'sim_choice_'+sim.gencode"
                                        name="simSelection" [data-cy]="'sim_choice_'+sim.gencode">
                                    <label [attr.for]="'sim_choice_'+sim.gencode" class="button is-size-4">{{sim.name}}</label>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>