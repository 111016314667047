import { StorageData } from '@repositories/storages/storage';

enum KEYS {
    SCORING = 'SCORING',
}

export class ScoringStorage extends StorageData{

    public static KEYS: typeof KEYS = KEYS;

    protected static prefix: string = 'checkout_';

    protected static sessionStorageKeys: string[] = Object.values(KEYS);

}
