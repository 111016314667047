import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CalendarEvent, CalendarMonthViewDay, CalendarView, DAYS_OF_WEEK } from 'angular-calendar';
import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    addDays,
    addMonths,
    addWeeks, endOfDay, endOfMonth, endOfWeek, isSameDay, isSameMonth,
    startOfDay,
    startOfMonth,
    startOfWeek,
    subDays,
    subMonths,
    subWeeks
} from 'date-fns';
import * as moment from 'moment';

// @dynamic
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'bytel-appointments-calendar',
    templateUrl: './appointments-calendar.component.html',
    styleUrls: ['./appointments-calendar.component.scss']
})
export class AppointmentsCalendarComponent implements OnInit, OnDestroy {
    @Input() appointments$: Observable<CalendarEvent[]>;
    @Input() storedAppointment?: CalendarEvent;
    @Input() prefillValueDemand?: Subject<boolean>;
    @Input() appointmentErrorSubject?: Subject<string>;
    @Input() headerTextAppointment = 'Sélectionner un créneau';
    @Input() customFirstDate?: Date;
    @Input() preselectDate?: Date;
    @Input() callOut?: boolean;

    @Output() selectAppointmentEmitter = new EventEmitter<CalendarEvent>();
    @Output() selectDayEmitter = new EventEmitter<CalendarMonthViewDay>();
    @Output() formStatusEmitter = new EventEmitter<string>();

    public viewDate: Date = new Date();
    view: CalendarView | string = CalendarView.Month;
    public events: any = [];
    public minDate: Date = new Date();
    public maxDate: Date = new Date();
    public prevBtnDisabled = false;
    public nextBtnDisabled = false;
    public activeDayIsOpen = true;
    public weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
    public form: FormGroup<{appointment: AbstractControl<string>}>;
    public appointments: CalendarEvent[];
    public appointment: CalendarEvent;
    public dayAppointments: CalendarEvent[];
    public selectedDay: CalendarMonthViewDay;
    public firstAppointment: CalendarEvent;
    public currentAppointmentError: string;
    public refreshSubject = new Subject();
    public isMobileAppointmentsBlockVisible = false;
    public isPreselectedDate = false;

    private _formStatusSubscription: Subscription;
    private _colors: {
        blue: {
            primary: string;
            secondary: string;
        };
    } = {
            blue: {
                primary: '#1e90ff',
                secondary: '#D1E8FF',
            },
        };

    public ngOnInit(): void {
        this._loadCalendar();
        this.form = new FormGroup<{appointment: AbstractControl<string>}>({
            appointment: new FormControl<string>('', Validators.required)
        });
        this._formStatusSubscription = this.form.statusChanges.subscribe(formStatus => this.formStatusEmitter.emit(formStatus));

        if (this.prefillValueDemand) {
            this.prefillValueDemand.subscribe(demand => demand ? this._prefillCalendar() : null);
        }

        if (this.appointmentErrorSubject) {
            this.appointmentErrorSubject.subscribe((errorMessage: string) => {
                this.currentAppointmentError = errorMessage;
                if (!!this.currentAppointmentError) {
                    this._handleError();
                }
            });
        }
    }

    public ngOnDestroy(): void {
        if (this._formStatusSubscription) {
            this._formStatusSubscription.unsubscribe();
        }
    }

    public increment(): void {
        this.activeDayIsOpen = false;
        this.changeDate(this.addPeriod(this.view, this.viewDate, 1));
    }

    public decrement(): void {
        this.activeDayIsOpen = false;
        this.changeDate(this.subPeriod(this.view, this.viewDate, 1));
    }

    public today(): void {
        this.changeDate(new Date());
    }

    public addPeriod(period: string, date: Date, amount: number): Date {
        return {
            day: addDays,
            week: addWeeks,
            month: addMonths,
        }[period](date, amount);
    }

    public subPeriod(period: string, date: Date, amount: number): Date {
        return {
            day: subDays,
            week: subWeeks,
            month: subMonths,
        }[period](date, amount);
    }

    public startOfPeriod(period: string, date: Date): Date {
        return {
            day: startOfDay,
            week: startOfWeek,
            month: startOfMonth,
        }[period](date);
    }

    public endOfPeriod(period: string, date: Date): Date {
        return {
            day: endOfDay,
            week: endOfWeek,
            month: endOfMonth,
        }[period](date);
    }

    public dateIsValid(date: Date): boolean {
        return date >= this.minDate && date <= this.maxDate;
    }

    public dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
        if (isSameMonth(date, this.viewDate)) {
            if (
                (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
                events.length === 0
            ) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
            }
            this.viewDate = date;
        }
    }

    public changeView(view: string): void {
        this.view = view;
        this.dateOrViewChanged();
    }

    public dateOrViewChanged(): void {
        this.prevBtnDisabled = !this.dateIsValid(
            this.endOfPeriod(this.view, this.subPeriod(this.view, this.viewDate, 1))
        );
        this.nextBtnDisabled = !this.dateIsValid(
            this.startOfPeriod(this.view, this.addPeriod(this.view, this.viewDate, 1))
        );
        if (this.viewDate < this.minDate) {
            this.changeDate(this.minDate);
        } else if (this.viewDate > this.maxDate) {
            this.changeDate(this.maxDate);
        }
    }

    public beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
        body.forEach((day) => {
            if (!this.dateIsValid(day.date)) {
                day.cssClass = 'cal-disabled';
            }
            if (this.appointment && !this.currentAppointmentError) {
                const isDaySelected: boolean = moment(day.date).isSame(this.appointment.start, 'day');
                if (isDaySelected) {
                    day.cssClass = 'cal-day-selected';
                    this.selectedDay = day;
                }
            }
        });
    }

    public handleSelectedDay(day: CalendarMonthViewDay, fromTemplate: boolean = false): void {
        if (fromTemplate) {
            this.isPreselectedDate = false;
        }
        if (day.events?.length) {
            this.setAppointment(null);
            if (this.selectedDay) {
                delete this.selectedDay.cssClass;
            }
            this.selectedDay = day;
            this.dayAppointments = day?.events;
            this.isMobileAppointmentsBlockVisible = true;
            day.cssClass = 'cal-day-selected';
            this.selectDayEmitter.emit(day);
        }
    }

    public setAppointment(event: CalendarEvent): void {
        this.appointment = event;
        this.selectAppointmentEmitter.emit(event);
        this.form.get('appointment').setValue(this.appointment?.meta);
        this.currentAppointmentError = null;
    }

    public closeMobileAppointmentsBlock(): void {
        this.isMobileAppointmentsBlockVisible = false;
    }

    private changeDate(date: Date): void {
        this.viewDate = date;
        this.dateOrViewChanged();
    }

    private _getDateAppointments(date: Date): CalendarMonthViewDay {
        const appointments: CalendarEvent[] = this.appointments.filter((appointment) => moment(appointment.start).isSame(date, 'day'));
        return {
            events: appointments
        } as CalendarMonthViewDay;
    }

    private _loadCalendar(): void {
        this.appointments$ = this.appointments$.pipe(
            map((events) => events.map((event) => {
                if (event.start < this.minDate) {
                    this.minDate = event.start;
                }

                if (event.end > this.maxDate) {
                    this.maxDate = event.end;
                }

                return event;
            })),
            map((appointments: CalendarEvent[]) => {
                this.appointments = appointments;
                if (this.appointments.length) {
                    this.firstAppointment = this.appointments[0];
                    this.changeDate(this.customFirstDate ?? this.firstAppointment.start);
                }

                if (this.storedAppointment?.start) {
                    const selectedDayAppointments: CalendarEvent[] = this.appointments
                        .filter((appointment) => moment(appointment.start).isSame(this.storedAppointment.start, 'day'));

                    selectedDayAppointments.sort((a: CalendarEvent, b: CalendarEvent) => moment(a.start).isAfter(b.start) ? 1 : -1);

                    let selectedAppointment: CalendarEvent = selectedDayAppointments
                        .find((appointment) => moment(appointment.start).isSame(this.storedAppointment.start, 'hour'));
                    if (!selectedAppointment) {
                        const startDate = moment(this.storedAppointment.start);
                        const endDate = moment(this.storedAppointment.end);
                        selectedAppointment = {
                            id: this.storedAppointment.id,
                            start: startDate.toDate(),
                            end: endDate.toDate(),
                            title: `De ${startDate.format('H:mm')} à ${endDate.format('H:mm')}`,
                            color: this._colors.blue,
                            meta: this.storedAppointment.meta
                        };
                        this.appointments.push(selectedAppointment);
                        if (this.selectedDay) {
                            this.selectedDay.events.push(selectedAppointment);
                        }
                    }
                    this.dayAppointments = selectedDayAppointments;
                    this._preSelectAppointment(selectedAppointment);
                } else if (this.preselectDate) {

                    const preselectAppointment: CalendarEvent = this.appointments
                        .find((appointment) => moment(appointment.start).isSameOrAfter(this.preselectDate, 'day'));

                    if (preselectAppointment) {
                        this.isPreselectedDate = true;
                        this._preSelectAppointment(preselectAppointment);
                    }
                }
                return this.appointments;
            })
        );
    }

    private _prefillCalendar(): void {
        const appointements: CalendarMonthViewDay = this._getDateAppointments(this.firstAppointment.start);
        this.handleSelectedDay(appointements);
        const selectedDayAppointments: CalendarEvent[] = this.appointments
            .filter((appointment) => moment(appointment.start).isSame(this.firstAppointment.start, 'day'));
        this.setAppointment(selectedDayAppointments[0]);
    }

    private _preSelectAppointment(selectedAppointment?: CalendarEvent): void {
        if (selectedAppointment) {
            const appointements: CalendarMonthViewDay = this._getDateAppointments(selectedAppointment.start);
            this.handleSelectedDay(appointements);
            this.setAppointment(selectedAppointment);
        }
    }

    private _handleError(): void {
        this.selectedDay = null;
        this.activeDayIsOpen = false;
        this.dayAppointments = null;
        this.viewDate = new Date();
        this.refreshSubject.next(null);
        this.form.get('appointment').setValue(null);
        this._loadCalendar();
        this.changeDate(this.viewDate);
    }
}
