import { Injectable } from '@angular/core';

import {
    Fai,
    FaimUnlimited,
    Option,
    Phone,
    Plan,
    Product,
    ProductFactory,
    PromotionModel,
    QuoteContextAcquisitionModel,
    QuoteContextFaiModel,
    QuoteContextModel, QuoteModel, Sim, Sowo
} from '@bytel/bytel-sales';
import { ALLOWED_CATEGORIES } from '@interfaces/api/catalog.interface';
import { ICartSerialize } from '@interfaces/serialize/cart-serialize.interface';
import { MainCartModel } from '@models/cart/main-cart.model';
import { FundingMethod } from '@models/payment-method';
import { CartSerialize } from '@repositories/serialize/cart.serialize';
import { CheckoutStorage } from '@repositories/storages/checkout.storage';
import { CartActiveService } from '@services/cart/cart-active.service';
import { CatalogService } from '@services/catalog.service';
import { forkJoin, from, Observable, of } from 'rxjs';
import { delayWhen, map, mergeMap, tap } from 'rxjs/operators';
import bind from '../../helper/decorators/bind';
import { OpportunityService } from '@services/opportunity.service';
import { SimService } from './sim.service';
import { ProductRepository } from '@repositories/product.repository';


@Injectable({
    providedIn: 'root'
})
export class CartTeleSalesService {

    public refreshObs: Observable<MainCartModel>;
    public cartModel: MainCartModel;

    constructor(private cartService: CartActiveService,
                private cartSerialize: CartSerialize,
                private simService: SimService,
                private opportunityService: OpportunityService,
                private readonly catalogService: CatalogService) {
        this.refreshObs = cartService.refreshObs;
        this.refreshObs.subscribe(this._save);
    }

    public setId(id: number): void {
        this.cartModel.cartId = id;
        this._save(this.cartModel);
    }

    public setQuoteId(quoteIndex: number, id: number): void{
        this.cartModel.quotes[quoteIndex].id = id;
        this._save(this.cartModel);
    }

    public setProductId(gencode: string, id: number): void{
        const product = this.cartModel.getQuote().products.find((p) => p.gencode === gencode);
        if (!!product) {
            product.id = id;
            this._save(this.cartModel);
        }
    }

    public saveToStorage(): void {
        this._save(this.cartModel);
    }

    public resolve(): Observable<MainCartModel>{
        if (this.cartModel){ return of(this.cartModel); }
        const cartSerialized: ICartSerialize = CheckoutStorage.getItem(CheckoutStorage.KEYS.CART);
        if (cartSerialized){
            return this.cartSerialize.getMainCartUnerialized(cartSerialized)
                .pipe(
                    tap((cartModel) => this.cartModel = cartModel),
                    mergeMap(() => this._addAndUpdateSim(this.cartModel)),
                    delayWhen(() => from(this.refresh()))
                );
        }
        this.cartModel = new MainCartModel();
        this.cartService.addQuote(this.cartModel);
        return of(this.cartModel);
    }

    public clear(): void{
        this.cartModel.cartId = null;
        this.cartModel.fundingMethod = null;
        this.cartService.clear(this.cartModel);
    }

    public cleanQuote(): void {
        const quotesTmp = { ...this.cartModel.quotes };
        Object.keys(quotesTmp).forEach(
            (index) => {
                if (quotesTmp[index].id) {
                    this.cartService.deleteParcours(this.cartModel.cartId, quotesTmp[index].id);
                    quotesTmp[index].product = [];
                }
                this.cartService.removeQuote(this.cartModel, Number(index));
            }
        );

        if (this.cartModel.quotes.length === 0) {
            this.addQuote(new QuoteContextAcquisitionModel());
        }
    }

    public clearQuotes(): void {
        if (!this.hasFaiInCart()) {
            this.clearQuoteFai();
        }
        if (!this.hasSimoInCart()) {
            this.clearQuoteAcquisition();
        }

        if (this.cartModel.quotes.length === 0) {
            this.addQuote(new QuoteContextAcquisitionModel());
        }
    }

    public clearQuoteAcquisition(): void {
        const quotesTmp = {...this.cartModel.quotes};
        Object.keys(quotesTmp).forEach(
            (index) => {
                if (quotesTmp[index].isAcquisition()) {
                    if (quotesTmp[index].id) {
                        this.cartService.deleteParcours(this.cartModel.cartId, quotesTmp[index].id);
                        quotesTmp[index].product = [];
                    }
                    this.cartService.removeQuote(this.cartModel, Number(index));
                }
            }
        );
    }

    public clearQuoteFai(): void {
        const quotesTmp = {...this.cartModel.quotes};
        Object.keys(quotesTmp).forEach(
            (index) => {
                if (quotesTmp[index].isAcquisitionFix()) {
                    if (quotesTmp[index].id) {
                        this.cartService.deleteParcours(this.cartModel.cartId, quotesTmp[index].id);
                        quotesTmp[index].product = [];
                    }
                    this.cartService.removeQuote(this.cartModel, Number(index));
                } else if (quotesTmp[index].getProductByType(Phone)) {
                    this.clear();
                    return;
                }
            }
        );
    }

    public getQuoteIndexByContext(context): number | null {
        let quoteIndex = null;
        Object.keys(this.cartModel.quotes).forEach(
            (index) => {
                if (this.cartModel.quotes[index].context instanceof context) {
                    quoteIndex = Number(index);
                }
            }
        );
        return quoteIndex;
    }

    public getIdOpportunitiesUsed(): string[] {
        let idOpportunitiesUsed = [];
        this.cartModel.quotes?.forEach(quote => {
            if (quote.idOpportunity) {
                idOpportunitiesUsed.push(quote.idOpportunity);
            }
        });

        // fix pour le cas refaire commande
        idOpportunitiesUsed = idOpportunitiesUsed.concat(...this.opportunityService.opportunity.opportunityDad.idOpportunitiesUsed);

        return idOpportunitiesUsed;
    }

    public hasSimoInCart(): boolean {
        const index = this.getQuoteIndexByContext(QuoteContextAcquisitionModel);
        if (index !== null) {
            const plan = this.cartModel.getQuote(index).getPrincipalProduct<Plan>('Plan');
            if (plan) {
                return !!ProductFactory.Is(plan, Sowo);
            }
        }
        return false;
    }

    public getPlanSimoInCart(): Plan {
        return this.hasSimoInCart()
            ?  this.cartModel.getQuote(this.getQuoteIndexByContext(QuoteContextAcquisitionModel)).getPrincipalProduct<Plan>('Plan')
            : null;
    }
    public getPlanFaiInCart(): Plan {
        return this.hasFaiInCart()
            ?  this.cartModel.getQuote(this.getQuoteIndexByContext(QuoteContextFaiModel)).getPrincipalProduct<Plan>('Plan')
            : null;
    }

    public hasFaiInCart(): boolean {
        const index = this.getQuoteIndexByContext(QuoteContextFaiModel);
        if (index !== null) {
            const plan = this.cartModel.getQuote(index).getPrincipalProduct<Plan>('Plan');
            if (plan) {
                return !!ProductFactory.Is(plan, Fai, true);
            }
        }
        return false;
    }

    public getQuoteHasProduct(): QuoteModel {
        return this.cartModel.quotes.find(q => q.products.length > 0);
    }

    public getQuoteIndexHasProduct(): number {
        return this.cartModel.quotes.findIndex(q => q.products.length > 0);
    }

    public canAddFaiInQuote(): Observable<boolean> {
        const planSimo = this.getPlanSimoInCart();
        if (planSimo) {
            return this.catalogService.getCategoriesAndChilds<Product>([ALLOWED_CATEGORIES.SIMOFAI_TELESALES], true)
                .pipe(map(data =>  !!data[0]?.products.find(p => (p.gencode === planSimo.gencode))));
        }
        return of(false);
    }

    public isQuoteMixed(): boolean {
        return this.hasSimoInCart() && this.hasFaiInCart();
    }

    public addQuote(context: QuoteContextModel): void {
        this.cartService.addQuote(this.cartModel,context);
    }

    public addProduct(product: Product, quoteIndex?: number, autoRefresh?: boolean): Observable<Product> {
        this._setIdOpportunity(quoteIndex);
        return this.cartService.addProduct(this.cartModel, product, quoteIndex, autoRefresh).pipe(
            mergeMap(() => this._cleanOptionsFromMixedQuote([product], quoteIndex).pipe(map(()=>product))),
        );
    }

    public async refresh(): Promise<void> {
        return this.cartService.refresh(this.cartModel);
    }

    public removeProduct(product: Product, quoteIndex?: number, autoRefresh?: boolean): Observable<Product> {
        return this.cartService.removeProduct(this.cartModel, product, quoteIndex, autoRefresh);
    }

    public removeEdpFromScoring(product: Product, quoteIndex?: number, autoRefresh?: boolean): Observable<Product> {
        return this.cartService.removeEdpFromScoring(this.cartModel, product, quoteIndex, autoRefresh);
    }

    public removeProductsByGencode(gencode: string, quoteIndex: number = 0, autoRefresh?: boolean): Observable<Product[]> {
        const quote: QuoteModel = this.cartModel.quotes[quoteIndex];
        const removeObs: Observable<Product>[] = [];
        quote.getProductsByGencode(gencode).forEach(product => {
            removeObs.push(this.removeProduct(product, quoteIndex, autoRefresh));
        });
        return forkJoin(removeObs);
    }

    public addManualPromotion(promo: PromotionModel): Observable<void> {
        return this.cartService.addManualPromotion(this.cartModel, promo);
    }

    public removeManualPromotion(promo: PromotionModel): Observable<void> {
        return this.cartService.removeManualPromotion(this.cartModel, promo);
    }

    public removeCouponPromotion(): Observable<void> {
        return this.cartService.removeCouponPromotion(this.cartModel);
    }

    public setCouponPromotion(coupon: PromotionModel): Observable<void> {
        return this.cartService.setCouponPromotion(this.cartModel,coupon);
    }

    public updateProductQty(product: Product, qty: number, quoteIndex: number = this.cartModel.currentQuoteIndex): Observable<Product[]>
    {
        const obs: Observable<any>[] = [];
        const quote: QuoteModel = this.cartModel.getQuote(quoteIndex);
        const quoteProducts: Product[] = quote.getProductsByGencode(product.gencode);
        const qtyInQuote = quoteProducts.length;

        const qtyDiff = qty - qtyInQuote;
        if (qtyDiff > 0) {
            for (let i = 0; i < Math.abs(qtyDiff); i++) {
                obs.push(this.addProduct(product, quoteIndex, true));
            }
        } else if (qtyDiff < 0) {
            for (let i = 0; i < Math.abs(qtyDiff); i++) {
                obs.push(this.removeProduct(quoteProducts[i], quoteIndex, true));
            }
        }
        return forkJoin(obs);
    }

    public addProducts(productsObs: (Product | string)[], quoteIndex: number = 0): Observable<Product[]> {
        this._setIdOpportunity(quoteIndex);
        return this.cartService.addProducts(this.cartModel, productsObs, quoteIndex).pipe(
            mergeMap((products) => this._cleanOptionsFromMixedQuote(products, quoteIndex)
            ));
    }

    public _setIdOpportunity(quoteIndex): void {
        const currentQuote = this.cartModel.quotes[quoteIndex];
        if (currentQuote && !currentQuote.idOpportunity) {
            currentQuote.idOpportunity = this.opportunityService.opportunity.opportunityDad.idOpportunity;
        }
    }

    public getProductQty(product, quoteIndex: number = this.cartModel.currentQuoteIndex): number {
        return this.cartModel.getQuote(quoteIndex).getProductsByGencode(product.gencode)?.length;
    }

    public isMigratingPlan(gencode?: string): boolean{
        const planGencode: string = this.cartModel.getQuote().getPrincipalProduct<Plan>('Plan')?.gencode;
        if (!planGencode || !gencode) {
            return false;
        }
        return planGencode !== gencode;
    }

    public setFundingMethod(fundingMethod: FundingMethod, refresh = true): Observable<boolean>{
        return this.cartService.setFundingMethod(this.cartModel,fundingMethod, refresh);
    }

    public setIdPerson(idPerson: string): void {
        this.cartModel.idPerson = idPerson;
    }

    public getCartService(): CartActiveService {
        return this.cartService;
    }

    @bind
    private _save(cartModel: MainCartModel): void{
        CheckoutStorage.setItem(CheckoutStorage.KEYS.CART,this.cartSerialize.getMainCartSerialized(cartModel));
    }

    @bind
    private _cleanOptionsFromMixedQuote(products: Product[], quoteIndex?: number): Observable<Product[]> {
        const plan: Product = products.find(p => ProductFactory.Is(p, Plan));
        const cartOptions: Option[] = this.cartModel.getQuote(quoteIndex).getProductsByType(Option)?.filter(
            (opt) => !plan?.autoAdd?.includes(opt.gencode) && opt.gencode !== ProductRepository.LOCKED_PLAN_GENCODE
        );

        if (!cartOptions.length || !this.isQuoteMixed() || !plan) {
            return of(products);
        }

        return forkJoin(cartOptions.map((option: Option)=>this.removeProduct(option, quoteIndex))).pipe(map(()=>products));
    }

    @bind
    private _addAndUpdateSim(cartModel: MainCartModel): Observable<MainCartModel> {
        const quoteIndex: number = cartModel.currentQuoteIndex;
        const plan: Plan = this.cartModel.getQuote(quoteIndex).getPrincipalProduct<Plan>('Plan');

        if (plan && (!ProductFactory.Is(plan, Fai) || ProductFactory.Is(plan, FaimUnlimited))) {
            return this.simService.updateSimProduct(cartModel, plan, quoteIndex).pipe(
                mergeMap((sim: Sim) => this.cartService.addProduct(cartModel, sim, quoteIndex, false)),
                map(() => this.cartModel)
            );
        }
        return of(cartModel);
    }

}
