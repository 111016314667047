import { Pipe, PipeTransform } from '@angular/core';
import { Fai } from '@bytel/bytel-sales';

enum RANGES_LABELS {
    small = 'Bbox fit',
    medium = 'Bbox must',
    medium_pro = 'Bbox Pro',
    large = 'Bbox ultym',
    large_pro = 'Bbox Pro+',
    standard = 'Bbox standard',
    sensation = 'Bbox sensation',
    miami_plus = 'Bbox miami plus',
    miami = 'Bbox miami',
    medium_xgbox = 'Standard XgBox',
    medium_tv_xgbox = 'Standard TV XgBox',
    ss_small = 'Série Spéciale Bbox fit',
    ss_medium = 'Série Spéciale Bbox must',
    ss_medium_xgbox = 'Série Spéciale XgBox',
    ss_large = 'Série Spéciale Bbox ultym',
    sl_small = 'Série Spéciale Bbox',
    sl_medium = 'Série Limitée Bbox must',
    sl_large = 'Série Limitée Bbox ultym',
    sl_large_smart_tv = 'Série Limitée Bbox ultym Smart TV',
    bbox_smart_tv = 'Bbox must Smart TV',
    bbox_smart_tv_large = 'Bbox ultym Smart TV',
    BOX_4G = '4G box',
    BOX_5G = '5G box',
    BTL = 'Série Spéciale Bbox',
    btl_xgbox = 'Série Spéciale XgBox',
    bbox_tv_plus = 'Bbox TV+'
}

@Pipe({
    name: 'rangeLabel'
})

export class RangeLabelPipe implements PipeTransform {

    public transform(product: Fai): string {
        const range: string = `${product.range}${product.isPro ? '_pro' : ''}`;
        return RANGES_LABELS[range];
    }

}
