<tlv-hero-title title="Test d’éligibilité aux offres Bbox" iconName="tri-box"></tlv-hero-title>
<div class="is-divider is-invisible"></div>
<div class="is-divider is-invisible"></div>
<div class="columns is-centered" data-cy="fai-techno-step">
    <div class="column is-10">
        <div class="box">
            <div class="box-content">
                <h2 class="icon-and-text has-cursor" (click)="goToPreviousNext()">
                    <span class="icon is-small is-circled is-small has-background-tertiary has-text-tertiary-invert"><i class="tri-arrow-left"></i></span>
                    <span>Technologies disponibles pour ce client</span>
                </h2>
            </div>

            <div class="columns is-vcentered">
                <div class="column is-6 is-offset-3">
                    <tlv-steps-summary-data></tlv-steps-summary-data>
                </div>
                <div class="column is-3 is-narrow has-text-right icon-map">
                    <tlv-weto-eligibility-map></tlv-weto-eligibility-map>
                </div>
            </div>

            <div class="is-divider is-invisible"></div>

            <div class="is-flex is-justified-center" *ngIf="warningMessageCDLOrderReco">
                <div class="notification is-small is-info has-body" data-cy="cdl-warning-order">
                    <div class="icon">
                        <i class="tri-exclamation-circle"></i>
                    </div>
                    <div class="body">
                        <h5 class="title">
                            <ng-container [ngSwitch]="true">
                                <ng-container *ngSwitchCase="orderRecovery?.fai?.isCDL && !isCDL">
                                    Le client n'est plus en construction de ligne : les frais de constructions de ligne
                                    indiqués sur le récapitulatif de sa précédente commande ne lui seront pas facturés.
                                </ng-container>
                                <ng-container *ngSwitchCase="orderRecovery?.fai?.isCDL && isCDL">
                                    Pour les offres xDSL ce client devra procéder à une construction de ligne.
                                </ng-container>
                                <ng-container *ngSwitchDefault></ng-container>
                            </ng-container>
                        </h5>
                    </div>
                </div>
            </div>

            <div class="content">
                <!-- HANDLE ERRORS -->
                <div class="notification is-small is-warning has-body" *ngIf="(isCDL && !orderRecovery) || hasNoOneEligible" data-cy="cdl-warning">
                    <div class="icon">
                        <i class="tri-exclamation-circle"></i>
                    </div>
                    <div class="body">
                        <p *ngIf="isCDL && !hasNoOneEligible">
                            <span class="is-block">Pour les offres xDSL ce client devra procéder à une construction de ligne.</span>
                            <span class="is-block has-text-weight-semibold">Des frais supplémentaires par rapport à sa commande initiale lui seront facturés sur sa première facture.</span>
                        </p>
                        <p *ngIf="hasNoOneEligible">
                            <span class="is-block">Aucune technologie éligible pour cette adresse.</span>
                        </p>
                    </div>
                </div>

                <form #formTechno="ngForm" (submit)="submit()">
                    <table class="table is-bordered is-fullwidth is-marginless main-table">

                        <colgroup>
                            <col class="col-group-width">
                            <col class="col-group-width" *ngFor="let tech of technos; i as index;">
                        </colgroup>

                        <thead>
                            <tr>
                                <th class="is-invisible"></th>
                                <th class="is-uppercase" *ngFor="let tech of technos; i as index;">{{tech.type}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Eligible</td>
                                <td *ngFor="let tech of technos; index as i;" [data-cy]="'techno-status-'+tech.type">
                                    <div class="under-construction" *ngIf="tech.status === faiStatus.CONSTRUCTION">
                                        <span class="icon is-small"><i class="tri-exclamation-circle-s"></i></span>
                                        <span class="info">Construction de ligne</span>
                                    </div>
                                    <i class="icon-item status-icon" *ngIf="tech.status !== faiStatus.CONSTRUCTION">
                                        <span class="icon is-medium has-text-danger" *ngIf="tech.status !== faiStatus.OK" [data-cy]="'NOK'"><i class="tri-times"></i></span>
                                        <span class="icon is-medium has-text-success" *ngIf="tech.status === faiStatus.OK" [data-cy]="'OK'"><i class="tri-check"></i></span>
                                    </i>
                                </td>
                            </tr>

                            <tr>
                                <td>Vitesse</td>
                                <td *ngFor="let tech of technos; index as i;" [data-cy]="'techno-speed-'+tech.type">
                                    <span class="na-info" *ngIf="tech.status === faiStatus.CONSTRUCTION">__</span>

                                    <div class="is-flex is-justified-center is-vcentered" *ngIf="tech.status === faiStatus.OK">
                                        <span class="icon-and-text">
                                            <span class="icon is-small has-text-info is-marginless"><i class="tri-upload"></i></span>
                                            <span class="is-size-6 has-text-weight-semibold" [data-cy]="'up'">{{tech.debitUpMax >= 1000 ? (tech.debitUpMax / 1000) : tech.debitUpMax}} {{tech.debitUpMax >= 1000 ? 'Gb' : 'Mb'}}/s</span>
                                        </span>
                                        <span class="icon-and-text">
                                            <span class="icon is-small has-text-warning is-marginless"><i class="tri-download"></i></span>
                                            <span class="is-size-6 has-text-weight-semibold" [data-cy]="'down'">{{tech.debitDownMax >= 1000 ? (tech.debitDownMax / 1000) : tech.debitDownMax}} {{tech.debitDownMax >= 1000 ? 'Gb' : 'Mb'}}/s</span>
                                        </span>
                                    </div>
                                    <p class="has-text-primary has-text-weight-bold" *ngIf="isTechnologiesPon(tech)">Eligible à l'Option Débit +</p>
                                    <span class="icon is-medium has-text-danger" *ngIf="tech.status !== faiStatus.CONSTRUCTION && tech.status !== faiStatus.OK" [data-cy]="'NOK'"><i class="tri-times"></i></span>
                                </td>
                            </tr>

                            <tr>
                                <td>Internet</td>
                                <td *ngFor="let tech of technos;" [data-cy]="'techno-internet-'+tech.type">
                                    <span class="icon is-medium has-text-danger" *ngIf="tech.status !== faiStatus.CONSTRUCTION && tech.status !== faiStatus.OK" [data-cy]="'NOK'"><i class="tri-times"></i></span>
                                    <span class="icon is-medium has-text-success" *ngIf="tech.status === faiStatus.CONSTRUCTION || tech.status === faiStatus.OK" [data-cy]="'OK'"><i class="tri-check"></i></span>
                                </td>
                            </tr>

                            <tr>
                                <td>TV</td>
                                <td *ngFor="let tech of technos; i as index;" [data-cy]="'techno-tv-'+tech.type">
                                    <span class="icon is-medium has-text-danger" *ngIf="(tech.status !== faiStatus.CONSTRUCTION && tech.status !== faiStatus.OK) || !canHaveTV(tech)" [data-cy]="'NOK'"><i class="tri-times"></i></span>
                                    <span class="icon is-medium has-text-success" *ngIf="(tech.status === faiStatus.CONSTRUCTION || tech.status === faiStatus.OK) && canHaveTV(tech)" [data-cy]="'OK'"><i class="tri-check"></i></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- Forcecast && Validation-->
                    <table class="table is-fullwidth main-table">
                        <colgroup>
                            <col class="col-group-width">
                            <col class="col-group-width" *ngFor="let tech of technos; i as index;">
                        </colgroup>
                        <!-- Without the thead the table lose his mind -->
                        <thead class="is-hidden">
                            <tr>
                                <th></th>
                                <th *ngFor="let tech of technos; i as index;"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="is-invisible no-border"></td>
                                <td class="no-border" *ngFor="let tech of technos; i as index;">

                                    <ng-container *ngIf="tech?.allowForecast?.eligible else noRedirection">
                                        <span [class.is-invisible]="!tech?.allowForecast?.date" *ngIf="tech?.allowForecast?.date && convertToReadableDate(tech?.allowForecast?.date)" class="is-size-8 is-block">{{convertToReadableDate(tech?.allowForecast?.date)}}</span>
                                        <ng-container *ngIf="!tech?.allowForecast?.date || !convertToReadableDate(tech?.allowForecast?.date)">
                                            <span class="is-size-8 is-invisible is-block">Non Eligible</span>
                                        </ng-container>
                                        <button type="button" class="button is-info is-small" [disabled]="isLoading" (click)="gotoForecastForm(tech)"
                                                [class.is-loading]="isLoading" [data-cy]="'techno-contact-'+tech.type">Être contacté</button>
                                    </ng-container>
                                    <ng-template #noRedirection>
                                        <div class="field no-select">
                                            <div class="control is-flex is-justified-center">
                                                <input  [data-cy]="'techno-checkbox-'+tech.type" class="is-checkradio" id="{{tech.type}}" [(ngModel)]="selectedTechno[tech.type]" [disabled]="(tech.status === faiStatus.NOK) || lockOnSingleTechnoAvailable" name="{{tech.type}}" type="checkbox">
                                                <label class="is-inline-flex" for="{{tech.type}}"></label>
                                            </div>
                                        </div>
                                    </ng-template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="buttons is-centered">
                        <button data-cy="next" class="button is-primary" [disabled]="isLoading || hasNoOneEligible">{{isLoading ? 'Traitement en cours...' : 'Continuer'}}</button>
                    </div>
                </form>

            </div>

        </div>
    </div>
</div>
