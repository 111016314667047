import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ReactiveFormsModule } from '@angular/forms';

import { GoogleMapsModule } from '@angular/google-maps';

import { SvgDirective } from './directives/svg.directive';
import { ScrollToDirective } from './directives/scrollTo.directive';
import { CmsBlockComponent } from './components/cms-block/cms-block.component';
import { AppointmentsCalendarComponent } from './components/appointments-calendar/appointments-calendar.component';
import { NavigateToDirective } from './directives/navigate-to.directive';
import { TrustHtmlPipe } from './pipes/trustHtml.pipe';
import { StoreComponent } from './components/store-locator/store/store.component';
import { StoreLocatorComponent } from './components/store-locator/store-locator.component';
import { GmapsComponent } from './components/gmaps/gmaps.component';
import { BasicObject } from '@interfaces/utils.inteface';
import { ConfigurationService } from './services/configuration.service';
import { Options } from './interfaces/options.interface';
import { configurationsProvider } from './services/configuration.provider';
import { CacheHttpInterceptor } from './services/cache-http.interceptor';
import { OPTIONS_CONFIG } from './config';
import { DataCyDirective } from './directives/data-cy.directive';

declare global {
    interface Window {
        ConfigInitial: BasicObject;
        tc_vars: BasicObject;
    }
}

const EXPORTS = [
    SvgDirective,
    ScrollToDirective,
    CmsBlockComponent,
    GmapsComponent,
    StoreLocatorComponent,
    StoreComponent,
    TrustHtmlPipe,
    NavigateToDirective,
    DataCyDirective,
    AppointmentsCalendarComponent
];

@NgModule({
    declarations: [...EXPORTS],
    imports: [
        CommonModule,
        GoogleMapsModule,
        HttpClientModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        HttpClientJsonpModule,
        ReactiveFormsModule
    ],
    providers: [ConfigurationService],
    exports: [...EXPORTS]
})
export class CommonModules {
    static forRoot(optionsConfig: () => Options): ModuleWithProviders<CommonModules> {
        return {
            ngModule: CommonModules,
            providers: [
                ...(!optionsConfig().disableAutoLoadConfig ?
                    [
                        {
                            provide: APP_INITIALIZER,
                            useFactory: configurationsProvider,
                            deps: [
                                ConfigurationService,
                                HttpClient
                            ],
                            multi: true
                        }
                    ]
                    :
                    []
                ),
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: CacheHttpInterceptor,
                    multi: true
                },
                {
                    provide: OPTIONS_CONFIG,
                    useValue: optionsConfig
                }
            ]
        };
    }
}
