import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'trustHtml'
})
export class TrustHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {}

    public transform(value): SafeHtml {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}
