import { IProduct, Option, EdpFms } from '@bytel/bytel-sales';

export class DisplayOptionModel extends Option {

    public available: boolean;
    public position: number;
    public firstPage: boolean;

    constructor(product: IProduct, available: boolean, position: number) {
        super(product);
        this.available = available;
        this.position = position;
        this.firstPage = product.firstPage;
    }

    public clone(): this{
        // @ts-ignore
        return new this.constructor(this.data,this.available,this.position);
    }

}

export class DisplayEdpFmsOptionModel extends EdpFms {

    public available: boolean;
    public position: number;
    public firstPage: boolean;

    constructor(product: IProduct, available: boolean, position: number) {
        super(product);
        this.available = available;
        this.position = position;
        this.firstPage = product.firstPage;
    }

    public clone(): this{
        // @ts-ignore
        return new this.constructor(this.data,this.available,this.position);
    }

}
