import { Component, OnInit } from '@angular/core';
import { BasicObject } from '@common-modules';
import {
    Accessory,
    CartModel,
    Plan,
    Product,
    PromotionsModel,
    QuoteContextAcquisitionModel,
    QuoteContextFaiModel,
    Sowo,
    TYPE_MARKETING } from '@bytel/bytel-sales';
import { QuoteMixedModalComponent } from '@components/checkout/step/cart/quote-mixed-modal/quote-mixed-modal.component';
import { DialogRef, DialogService } from '@ngneat/dialog';
import { CatalogService } from '@services/catalog.service';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { CartService } from '@services/checkout/cart.service';
import { StepperService } from '@services/checkout/stepper.service';
import { PromotionsService } from '@services/promotions.service';
import { of, Observable } from 'rxjs';
import { delayWhen, finalize, map, tap } from 'rxjs/operators';
import bind from 'src/app/helper/decorators/bind';
import { ALLOWED_CATEGORIES } from '@interfaces/api/catalog.interface';
import { QualificationService } from '@services/qualification.service';
import { SalesUserService } from '@services/sales-user.service';
import { OpportunityService } from '@services/opportunity.service';
import { ProductRepository } from '@repositories/product.repository';

@Component({
    selector: 'tlv-cross-sell-plan',
    templateUrl: './cross-sell-plan.component.html',
    styleUrls: ['./cross-sell-plan.component.scss']
})
export class CrossSellPlanComponent implements OnInit {

    public TYPE_MARKETING: typeof TYPE_MARKETING = TYPE_MARKETING;
    public config: BasicObject;
    public products: Product[] = [];
    public isLoading = false;

    constructor(
        private cartTeleSalesService: CartTeleSalesService,
        private cartService: CartService,
        private readonly catalogService: CatalogService,
        private dialogService: DialogService,
        private stepperService: StepperService,
        private promotionsService: PromotionsService,
        private qualificationService: QualificationService,
        private salesUserService: SalesUserService,
        private opportunityService: OpportunityService,
    ) {
    }

    public ngOnInit(): void {
        this._updateProducts();
    }

    public isInCart(plan: Product): boolean {
        const index = this.cartTeleSalesService.getQuoteIndexByContext(QuoteContextAcquisitionModel);
        if (index !== null) {
            const product = this.cartTeleSalesService.cartModel.getQuote(index).getProductsByGencode(plan.gencode);
            return product.length > 0;
        } else {
            return false;
        }
    }

    public removeProduct(plan: Product): void {
        const indexQuoteAcquisition = this.cartTeleSalesService.getQuoteIndexByContext(QuoteContextAcquisitionModel);
        if (indexQuoteAcquisition !== null) {
            this.cartTeleSalesService.removeProductsByGencode(plan.gencode).subscribe();
        }
    }


    public checkDadThenAddToCart(product: Product, isLocked = false): void {
        if (!this.qualificationService.isDadCampaign()) {
            this.addToCart([product.gencode, ...isLocked ? [ProductRepository.LOCKED_PLAN_GENCODE] : []]);
            return;
        }
        const dialogRef: DialogRef<any> = this.opportunityService.openOpportunityDadModal();
        dialogRef.afterClosed$.subscribe((results: { [key: string]: any }) => {
            if (!results?.continue) {
                this.salesUserService.closeCall();
            } else {
                this.addToCart([product.gencode, ...isLocked ? [ProductRepository.LOCKED_PLAN_GENCODE] : []]);
            }
        });
    }


    public addToCart(productsGencodes: string[]): void {
        let indexQuoteAcquisition = this.cartTeleSalesService.getQuoteIndexByContext(QuoteContextAcquisitionModel);
        this.catalogService.getProductsByGencodes(productsGencodes)
            .pipe(
                delayWhen(()=>indexQuoteAcquisition ? of(null) : of(this.cartTeleSalesService.addQuote(new QuoteContextAcquisitionModel()))
                    .pipe(
                        tap(()=>{ indexQuoteAcquisition = this.cartTeleSalesService.getQuoteIndexByContext(QuoteContextAcquisitionModel);}))
                ),
                delayWhen(() => {
                    if (indexQuoteAcquisition !== null) {
                        const plan = this.cartTeleSalesService.cartModel.getQuote(indexQuoteAcquisition, true).getProductByType(Plan);
                        if (plan) {
                            return this.cartTeleSalesService.removeProductsByGencode(plan.gencode, indexQuoteAcquisition);
                        }
                    }
                    return of(null);
                }),
                delayWhen(products => this.cartTeleSalesService.addProducts(products, indexQuoteAcquisition)),
                finalize(() => this.isLoading = false)
            ).subscribe();
    }

    public checkAccessoryAndRemove(product: Product, isLocked = false): void{
        this.isLoading = true;
        const index = this.cartTeleSalesService.getQuoteIndexByContext(QuoteContextFaiModel);
        const accessories = this.cartTeleSalesService.cartModel.getQuote(index)?.getProductsByType(Accessory);
        if (index === null || accessories.length === 0) {
            this.checkDadThenAddToCart(product, isLocked);
            return;
        }

        const refDialog = this.dialogService.open(
            QuoteMixedModalComponent, {data: {isSimo: true, accessory: true}, closeButton: false, enableClose: false}
        );
        refDialog.afterClosed$.subscribe((skip) => {
            if (skip) {
                if (index !== null) {
                    accessories.forEach(
                        (accessory)=>this.cartTeleSalesService.removeProductsByGencode(accessory.gencode,index).subscribe()
                    );
                }
                this.checkDadThenAddToCart(product, isLocked);
            } else {
                if (!this.stepperService.goToNextStep()) {
                    this.isLoading = false;
                }
            }

        });
    }

    @bind
    private _updateProducts(): void {
        this.catalogService.getCategoriesAndChilds<Product>([ALLOWED_CATEGORIES.SIMOFAI_TELESALES], true)
            .pipe(
                map(data => this.products = data[0].products.filter(p => (p instanceof Sowo))),
                delayWhen(() => this._preloadPromotionsQuoteMixed(this.products)),
                map((plans: Product[]) => this.products = plans)
            )
            .subscribe();
    }

    @bind
    private _preloadPromotionsQuoteMixed(products: Product[]): Observable<Product[]>{
        const listGencode = products.map(p=>p.gencode);
        const cartModel = this.cartTeleSalesService.cartModel;
        this.cartTeleSalesService.clearQuoteAcquisition();
        const indexQuote = this.cartTeleSalesService.getQuoteIndexByContext(QuoteContextAcquisitionModel);

        if (indexQuote === null) {
            this.cartTeleSalesService.addQuote(new QuoteContextAcquisitionModel());
        }

        return this.promotionsService.preloadPromotions(cartModel,
            listGencode,
            this.cartTeleSalesService.getQuoteIndexByContext(QuoteContextAcquisitionModel))
            .pipe(
                map((promotionModels: {[index: string]: PromotionsModel}) => {
                    products.forEach(
                        (product) => {
                            product.promotions = promotionModels[product.gencode];
                            product.calculatePrices(
                                cartModel.getQuote(this.cartTeleSalesService.getQuoteIndexByContext(QuoteContextAcquisitionModel)),
                                promotionModels[product.gencode]
                            );}
                    );
                    return products;
                }
                )
            );

    }

    @bind
    private _getCartForProduct(planSelected?: Plan): (product: Product) => Observable<CartModel>{
        return (product: Product): Observable<CartModel>=>
            this.cartService.generateCart([
                product,
                ...(!!planSelected ? [planSelected] : [])
            ]);
    }
}
