import { Injectable } from '@angular/core';
import { Oauth2ResourcesService, SHIPPING_MODES, ConfigurationService } from '@common-modules';
import {
    Activation,
    CartModel,
    CUSTOMER_CATEGORY,
    Delivery,
    Fai,
    FaiBox,
    FaimUnlimited,
    Plan,
    PRICE_TYPES,
    Product,
    ProductFactory,
    PromotionModel,
    QUOTE_CONTEXTS,
    QuoteModel,
    Sensation,
    Simo,
    Sowo
} from '@bytel/bytel-sales';
import { CartApi, ParcoursApi } from '@interfaces/api/create/panier';
import { ApiOrcomActionsInput, ApiTicketCaisseFormParams } from '@interfaces/api/order/order.interface';
import {
    IPortabilitySimPricing,
    PriceTypeEnum,
    ProductApiResult
} from '@interfaces/api/sales.interface';
import { CustomerProGpDetailsModel } from '@interfaces/customer.interface';
import { IStoreCheckout } from '@interfaces/store-checkout.interface';
import { AddressModel } from '@models/cart/address.model';
import { AppointmentModel } from '@models/cart/appointment.model';
import { DeliveryAppointmentModel } from '@models/cart/delivery-appointment.model';
import { MainCartModel } from '@models/cart/main-cart.model';
import { MobileRecoveryModel } from '@models/cart/mobile-recovery.model';
import { PortabilityPriceModel } from '@models/cart/portability-price.model';
import { PortabilityModel } from '@models/cart/portability.model';
import { CustomerModel } from '@models/customer/customer.model';
import { FaiCartModel } from '@models/fai/fai-cart.model';
import { PaymentResponseModel } from '@models/payment-response.model';
import { QualificationModel } from '@models/qualification';
import { SalesForceRepository } from '@repositories/sales-force.repository';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import { format, parseISO } from 'date-fns';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { USER_TITLES } from '../constants/customer';
import bind from '../helper/decorators/bind';
import { SalesApiHelper } from '@helper/sales-api.helper';
import { SimsByParcoursModel } from '@models/cart/sims-by-parcours';
import { SimsTypesModel } from '@models/cart/sim-type.model';

interface ApiProductsParamsData {
    products: {gencode: string}[];
    edp: boolean;
}

interface ApiProductsParams {
    data: ApiProductsParamsData;
    hash: string;
}

interface GenericContextParcours {
    idPU?: number;
    clientCategorie: CUSTOMER_CATEGORY;
    parcours: GenericContextProduits [];
}

export enum CatalogType {
    BYTEL = 'BYTEL'
}

interface GenericContextProduits {
    id: string;
    idContrat?: string;
    produits: GenericContextProduit[];
    estCourant: boolean;
    type: string;
    codePostalLivraison: string;
}

interface GenericContextProduit {
    id: string;
    gencode: string;
    catalogue: CatalogType.BYTEL;
}

@Injectable({
    providedIn: 'root'
})
export class SalesRepository {

    public cachedCoupon: string = null;
    public cachedParamsListeProduits: string = null;

    constructor(
        private oauth2Resource: Oauth2ResourcesService,
        private salesForceRepository: SalesForceRepository,
        private configService: ConfigurationService,
        private faiEligibilityService: FaiEligibilityService
    ) { }

    public pushCoupon(cart: MainCartModel,promotion: PromotionModel): Observable<boolean>{
        return promotion && cart.cartId && this.cachedCoupon !== promotion.code ? this.oauth2Resource.useSalesApi()
            .ventes().panier(cart.cartId).promotions().post({
                code : promotion.code
            }).pipe(
                tap(() => this.cachedCoupon = promotion.code),
                map(()=>true),
                catchError(()=>of(false)))
            : of(false);
    }

    public pushManualPromotion(cart: MainCartModel,promotion: PromotionModel): Observable<boolean>{
        return promotion && cart.cartId ? this.oauth2Resource.useSalesApi()
            .ventes().panier(cart.cartId).promotions().post({
                id : +promotion.id,
                type: 'MANUAL'
            }).pipe(map(()=>true),catchError(()=>of(false)))
            : of(false);
    }

    public removeCoupon(cart: MainCartModel): Observable<boolean>{
        return cart.promotions.coupon && cart.cartId ? this.oauth2Resource.useSalesApi()
            .ventes().panier(cart.cartId).promotions(cart.promotions.coupon.id).delete()
            .pipe(tap(() => this.cachedCoupon = null),catchError(()=>of(true)),map(()=>true),catchError(()=>of(false)))
            : of(false);
    }

    public getSimsContextualises(
        cart: MainCartModel,
        customerId: number,
        contractId: number,
        quoteIndex: number
    ): Observable<SimsByParcoursModel[]> {
        return this.oauth2Resource
            .catalogue()
            .simsContextualises()
            .setLocalService()
            .useSalesApi()
            .post(this._buildParamsForSimsContextualises(cart, customerId, contractId))
            .pipe(
                map((pricings)=>(this._createSimParcoursModel(pricings, quoteIndex)))
            );
    }

    public deleteManualPromotion(cart: MainCartModel): Observable<number> {
        return this.oauth2Resource
            .useSalesApi()
            .ventes()
            .panier(cart.cartId)
            .promotions(cart.promotions.coupon?.id)
            .delete()
            .pipe(
                tap(() => cart.promotions.coupon = null)
            );
    }

    public createCart(
        cartModel: MainCartModel,
        qualification: QualificationModel
    ): Observable<number> {
        const cartParamsRequest: CartApi = this._buildCartParams(cartModel,qualification);
        return this.oauth2Resource
            .ventes()
            .panier()
            .setLocalService()
            .useSalesApi()
            .post(cartParamsRequest)
            .pipe(
                map((cartApi)=>cartApi.idPanier)
            );
    }

    public checkCartIntegrity(cartId: number): Observable<any> {
        return this.oauth2Resource
            .ventes()
            .panier(cartId)
            .verifierPanier()
            .setLocalService()
            .useSalesApi()
            .get();
    }

    public pushProducts(
        cartId: number,
        quote: QuoteModel,
        portability: PortabilityModel,
        faiCart: FaiCartModel
    ): Observable<boolean> {
        if (!cartId || !quote.id || !quote.products.length){return of(false);}
        const params: ApiProductsParams = this._getApiProductsParams(quote, portability, faiCart);
        if (this.cachedParamsListeProduits !== params.hash) {
            this.cachedCoupon = null;
        }
        this.cachedParamsListeProduits = params.hash;
        return this.oauth2Resource
            .ventes()
            .panier(cartId)
            .parcours(quote.id)
            .listeProduits()
            .setLocalService()
            .useSalesApi()
            .post(params.data)
            .pipe(
                map((data: ProductApiResult[]) => {
                    data.forEach((productApi) => {
                        const product: Product = quote.products.find((p) => p.gencode === productApi.gencode);
                        if (product) {
                            product.id = productApi.idProduit;
                        }
                    });
                }),
                map(()=>true)
            );
    }

    public updateProduct(
        cartId: number,
        quote: QuoteModel,
        product: Product,
        portability: PortabilityModel,
        faiCart: FaiCartModel,
        appointment: AppointmentModel): Observable<boolean> {
        const params = {
            id: product.id,
            gencode: product.gencode,
            ...this._getProductAdditionnalData(quote, product, portability, faiCart, appointment, 'PUT')
        };
        return this.oauth2Resource
            .ventes()
            .panier(cartId)
            .parcours(quote?.id)
            .produits(product.id)
            .setLocalService()
            .useSalesApi()
            .put(params)
            .pipe(
                mergeMap(() => of(quote.products)),
                map(()=>true)
            );
    }

    public updateParcours(
        cartId: number,
        quote: QuoteModel,
        customer: CustomerProGpDetailsModel,
        faiEligId: string): Observable<number> {
        const params: ParcoursApi = this._serializeQuote(quote, customer, faiEligId);
        return this.oauth2Resource
            .ventes()
            .panier(cartId)
            .parcours(quote.id)
            .setLocalService()
            .useSalesApi()
            .put(params).pipe(
                map((data) => data.idParcours)
            );
    }

    public createParcours(
        cartId: number,
        quote: QuoteModel,
        customer: CustomerProGpDetailsModel,
        faiEligId: string): Observable<number> {
        const params: ParcoursApi = this._serializeQuote(quote, customer, faiEligId);
        return this.oauth2Resource
            .ventes()
            .panier(cartId)
            .parcours()
            .setLocalService()
            .useSalesApi()
            .post(params).pipe(
                map((data) => data.idParcours)
            );
    }

    public pushBilling(cartId: number,quote: QuoteModel, address: AddressModel): Observable<void> {
        return this.oauth2Resource
            .ventes()
            .panier(cartId)
            .parcours(quote.id)
            .adresseFacturation()
            .setLocalService()
            .useSalesApi()
            .post({
                codePostal: address.postalCode,
                ville: address.city,
                rue: address.street,
                numeroRue: address.streetNumber,
                complementAdresse: address.complement,
                controleAutoGCP: address.valid
            });
    }

    public updateEdpOnParcours(cart: MainCartModel, params: {edp: boolean}): Observable<boolean> {
        return this.oauth2Resource
            .ventes()
            .panier(cart.cartId)
            .parcours(cart.getQuote().id)
            .setLocalService()
            .useSalesApi()
            .put(params).pipe(
                map(()=>true)
            );
    }

    public deleteParcours(cartId: number, quoteId: string): Observable<boolean> {
        return this.oauth2Resource
            .ventes()
            .panier(cartId)
            .parcours(quoteId)
            .setLocalService()
            .useSalesApi()
            .delete().pipe(
                map(()=>true)
            );
    }

    public createOrder(paymentType: string, differedFMSPayment: boolean, cartId: number): Observable<any> {
        return this.oauth2Resource
            .ventes()
            .panier(cartId)
            .commande()
            .setLocalService()
            .useSalesApi()
            .post({
                ...(differedFMSPayment ? {paiementDiffereFraisMiseService: differedFMSPayment} : {}),
            });
    }

    public notifyClientWithOrderSummary(cartId: string, personId: string): Observable<boolean> {
        if (this.configService.data_refconf?.checkout?.recap_ccee !== true) {
            return of(true);
        }
        return this.oauth2Resource
            .useXProcess()
            .demandesCommunication()
            .post({
                contexte: 'RECAPITULATIF_CONTRACTUEL',
                identifiantTechnique:
                {
                    numeroPersonne: personId,
                    idPanier: cartId
                }
            }).pipe(map(()=>true));
    }

    public checkAndUpdateIban(params: {iban: string}, cartId: number, quoteId: number): Observable<boolean> {
        return this.oauth2Resource
            .ventes()
            .panier(cartId)
            .parcours(quoteId)
            .iban()
            .setLocalService()
            .useSalesApi()
            .post({iban:params.iban.toUpperCase()})
            .pipe(map(()=>true));
    }

    public pushPaymentMode(url: string, params: ApiOrcomActionsInput): Observable<PaymentResponseModel> {
        return this.oauth2Resource
            .setLocalService()
            .useSalesApi()
            .setUrl(url)
            .post(params)
            .pipe(
                map(
                    (res: ApiTicketCaisseFormParams) =>{
                        if (res.retourPSP?.method === 'GET'){
                            const urlData = res.retourPSP?.formUrl.split('?');
                            if (urlData.length > 1) {
                                res.retourPSP.formUrl = urlData[0];
                                res.retourPSP.formInput = urlData[1]?.split('&')
                                    .reduce((acc,val)=>{
                                        const keyVal = val.split('=');
                                        return [...acc,{nom:keyVal[0],valeur:keyVal[1]}];
                                    },[]);
                            }
                        }
                        return new PaymentResponseModel({
                            formDataToPost: {
                                url: res.retourPSP?.formUrl,
                                method: res.retourPSP?.method,
                                ...(res.retourPSP?.formInput ? {
                                    inputs: res.retourPSP?.formInput
                                        .map((input: {nom: string; valeur: string}) => ({name: input.nom, value: input.valeur}))
                                } : {}),
                            }
                        });
                    }
                )
            );
    }

    public pushDelivery(cart: MainCartModel,
                        quote: QuoteModel,
                        address: AddressModel,
                        customer: CustomerModel,
                        delivery: Delivery,
                        store: IStoreCheckout,
                        appointment: DeliveryAppointmentModel): Observable<void>{
        return this.oauth2Resource
            .ventes()
            .panier(cart.cartId)
            .parcours(quote.id)
            .adresseLivraison()
            .useSalesApi()
            .post({
                controleAutoGCP:address.valid,
                adresse:{
                    codePostal: address.postalCode,
                    ville: address.city,
                    rue: address.street,
                    pays: 'FRA',
                    numeroRue: address.streetNumber,
                    complementAdresse: address.complement,
                    civilite: customer.gender === USER_TITLES.missis ? 'MME' : 'M.',
                    nom: customer.lastname,
                    prenom: customer.firstname
                },
                modeLivraison: {
                    gencode: delivery.gencode,// POINT_RELAIS
                    ...(delivery.gencode === SHIPPING_MODES.RELAIS_EXPRESS ?
                        {pointRelais: {
                            codeIdentification: store.id,
                            libelle: store.name
                        }} : {}),
                    ...(delivery.gencode === SHIPPING_MODES.RELAIS_COLIS ?
                        {pointRelais: {
                            codeIdentification:store.id,
                            libelle:store.name,
                            codeSecteurLivraison:store.rvc
                        }} : {}),
                    ...(delivery.gencode === SHIPPING_MODES.LIVRAISON_RDV ?
                        {creneauChoisi: appointment?.dataToUpdate} : {})
                }
            });
    }

    private _serializeQuote(quote: QuoteModel, customer: CustomerProGpDetailsModel, faiEligId: string): ParcoursApi {
        const salesForceData = this.salesForceRepository.getData();
        const birthDate: Date = customer?.birthDate;
        const quoteContextLabels = {
            [QUOTE_CONTEXTS.ACQUISITIONFIX]: 'acquisitionFix',
            [QUOTE_CONTEXTS.ACQUISITION]: 'acquisition',
            [QUOTE_CONTEXTS.RENEW]:  'renouvellement',
        };
        const parcoursApi: ParcoursApi =  {
            contextProduit: {
                contextService: {
                    parcours: {
                        sourceProspect: salesForceData.sourceProspect,
                        typeAction: quote.id ? '' : 'INIT',
                        typeParcours: quote.context.id,
                        idPU: +customer?.idPerson,
                        idTemporaireProspect:salesForceData.idTemporaireProspect,
                        idProspect:salesForceData.idProspect
                    },
                    ...(this.configService?.data_refconf?.fai?.widget?.active
                        ? {[quoteContextLabels[quote.context.id]]: {idEligFai: faiEligId}}
                        : {}),
                    client: customer ? {
                        email: customer.email,
                        civilite: customer.gender,
                        nom: customer.lastname,
                        prenom: customer.firstname,
                        departementNaissance: customer.birthDepartment,
                        dateNaissance: format(parseISO(birthDate.toJSON()),'yyyyMMdd'),
                        telephone: customer.phone.replace('0', '+33'),
                        rna: customer.rna
                    } : {},
                }
            },
            consent: {
                toBeContactedMailSms: true,
                toBeContacted: true,
                toBeContactedProposal: false
            },
        };

        if (quote.idOpportunity) {
            parcoursApi.contextProduit.contextService.parcours.idOpportunite = quote.idOpportunity;
        }

        return parcoursApi;
    }

    private _buildParamsForSimsContextualises(
        cartModel: CartModel,
        customerId: number,
        contractId: number
    ): GenericContextParcours {
        return {
            ...(customerId ? {idPU: customerId} : {}),
            clientCategorie: cartModel.isPro ? CUSTOMER_CATEGORY.PRO : CUSTOMER_CATEGORY.GP,
            parcours: this._getCartsQuotes(cartModel, contractId)
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private _getCartsQuotes(cart: CartModel, contractId: number): GenericContextProduits[] {
        return cart.quotes.map((quote: QuoteModel) =>
            ({
                id: quote?.id?.toString(),
                ...(contractId ? {idContrat: contractId?.toString()} : {}),
                produits: [...this._getProductsContext(quote)].filter(p=>!!p),
                estCourant: this._doCurrentQuoteHasSim(quote),
                type: quote.context.id,
                codePostalLivraison: this.faiEligibilityService?.currentCart?.eligibility?.installationAddress?.postal
            })
        );
    }

    private _doCurrentQuoteHasSim(quote: QuoteModel): boolean {
        const plan: Plan = quote.getProductByType('Plan');
        return !!plan && (!ProductFactory.Is(plan, Fai) || ProductFactory.Is(plan, FaimUnlimited));
    }

    private _getProductsContext(quote: QuoteModel): GenericContextProduit[] {
        return quote.products.filter(p=>SalesApiHelper.IsApiProduct(p)).map((product)=>({
            gencode: product.gencode,
            catalogue: CatalogType.BYTEL,
            id: product?.id?.toString(),
        }));
    }

    private _buildCartParams(cartModel: MainCartModel, qualification: QualificationModel): CartApi {
        const OrderRecoveryCartId: string = this.salesForceRepository.getData()?.order?.id;
        return {
            idPanier: cartModel.cartId || null,
            parcours: cartModel.quotes.length ? [] : null,
            parcourCourant: cartModel.getQuote().id || null,
            canal: 'tlv',
            typologieAppel:qualification.calltype === 'in' ? 'IN' : 'OUT',
            campagne : qualification.campaign,
            idPanierRetours: this.salesForceRepository.getData()?.order?.idPanierRetours,
            version: this.salesForceRepository.getData()?.order?.version,
            ...(OrderRecoveryCartId ? {idCommandeInitiale: OrderRecoveryCartId} : {})
        };
    }

    private _getApiProductsParams(
        quote: QuoteModel,
        portability: PortabilityModel,
        faiCart: FaiCartModel,
        appointment?: AppointmentModel): ApiProductsParams {
        const params: ApiProductsParamsData = {
            products: [],
            edp: false
        };

        quote.products.forEach(product => {
            if (SalesApiHelper.IsApiProduct(product, quote)) {
                params.products.push({
                    gencode: product.gencode,
                    ...this._getProductAdditionnalData(quote, product, portability, faiCart, appointment)
                });
            }
        });

        return {
            data: params,
            hash: JSON.stringify(params)
        };
    }

    private _getProductAdditionnalData(
        quote: QuoteModel,
        product: Product,
        portability: PortabilityModel,
        faiCart: FaiCartModel,
        appointment?: AppointmentModel,
        method: string = 'POST'
    ): {[key: string]: any} {
        switch (true) {
            case ProductFactory.Is(product, Sensation, true):
            case ProductFactory.Is(product, Simo, true):
            case ProductFactory.Is(product, Sowo, true):{
                if (portability) {
                    return {
                        rio: {
                            idPorta: portability.id,
                            noTelephoneAPorter: portability.phoneNumber.replace('/^0/', '+33'),
                            phoneNumber: portability.phoneNumber.replace('/^0/', '+33'),
                            codeRio: portability.rioCode
                        },
                        typePortabilite: 'PORTABILITE_ENTRANTE'
                    };
                }
                return {};
            }
            case ProductFactory.Is(product, Fai):{
                return this._getFaiAdditionalData(product, faiCart, portability);
            }
            case ProductFactory.Is(product, Activation, true):
                return {
                    sku: product.data.sku
                };
            case ProductFactory.Is(product, FaiBox, true):{
                let additionnalData: {[key: string]: any} = {};
                const faiProduct: Fai = quote.getProductByType<Fai>('Fai');
                additionnalData.technology = faiProduct.technology;
                if (method === 'PUT') {
                    if (faiCart.eligibility && !this.configService?.data_refconf?.fai?.widget?.active) {
                        additionnalData.eligTech = faiCart.eligibility.webshopData;
                    }
                    additionnalData = this._addAppointmentForBox(additionnalData, appointment);
                }
                return additionnalData;
            }
            case ProductFactory.Is(product, MobileRecoveryModel,false): {
                const additionnalData: {[key: string]: any} = {};
                additionnalData.montantEstimeReprise = product.prices.final;
                additionnalData.montantBonus = product.partnerData.bonus;
                additionnalData.idDevisPartenaire = product.partnerData.quoteId;
                additionnalData.terminal =  {
                    idProduit: product.id,
                    nom: product.name,
                    marque: product.brand
                };
                additionnalData.criteresQualification = product.partnerData.surveys.map((survey) => (
                    {
                        critere: survey.question,
                        valeur: survey.response.value === 'Oui'
                    }
                ));
                additionnalData.assurance = product.partnerData.hasInsurance;
                return additionnalData;
            }
            default:
                return {};
        }
    }

    private _createSimParcoursModel(apiData: IPortabilitySimPricing, quoteIndex: number): SimsByParcoursModel[] {
        return apiData.parcours.map((parcour, id) =>
            new SimsByParcoursModel({
                id,
                sims: parcour.simsCompatibles.map(sim => new SimsTypesModel({
                    gencode: sim.gencode,
                    isPrefered: sim.prefere,
                    type: sim.type,
                    ...('terminalCompatible' in sim ? {isCompatibleWithDevice: sim?.terminalCompatible} : {isCompatibleWithDevice: true})
                })),
                portabilityPrices: this._createPortabilityPriceModel(apiData, quoteIndex)
            })
        );
    }

    @bind
    private _createPortabilityPriceModel(apiData: IPortabilitySimPricing, quoteIndex: number): PortabilityPriceModel[] {
        const priceTypes: Record<string, PRICE_TYPES> = {
            [PriceTypeEnum.IMMEDIAT]: PRICE_TYPES.Today,
            [PriceTypeEnum.AUTRE]: PRICE_TYPES.Other,
            [PriceTypeEnum.MENSUEL]: PRICE_TYPES.EveryMonth,
            [PriceTypeEnum.DIFFERE]: PRICE_TYPES.Reported
        };

        return apiData?.parcours[quoteIndex]?.simsCompatibles
            .map(sim => new PortabilityPriceModel({
                gencode: sim.gencode,
                withPorta: {
                    price: sim.prix,
                    type: priceTypes[sim.typePrix]
                },
                withoutPorta: {
                    price: sim.prix,
                    type: priceTypes[sim.typePrix]
                },
            }));

    }

    private _getFaiAdditionalData(product: Product, faiCart: FaiCartModel, portability: PortabilityModel): {[key: string]: any}  {
        let additionnalData: {[key: string]: any} = {};
        if (faiCart) {
            if (!this.configService?.data_refconf?.fai?.widget?.active) {
                additionnalData = {
                    eligTech: faiCart.eligibility.webshopData
                };
            }

            if (ProductFactory.Is(product, FaimUnlimited,true)) {
                additionnalData = this._addDataForFaimUnlimited(additionnalData, faiCart);
            }
            additionnalData = this._addPortabilityForFai(additionnalData, portability, faiCart.id);
            additionnalData = this._addLoginAndViopForFai(additionnalData, faiCart);
        }
        additionnalData.type_id = 'fai';
        return additionnalData;
    }

    private _addDataForFaimUnlimited(additionnalData: {[key: string]: any}, faiCart: FaiCartModel): {[key: string]: any} {
        return {
            ...additionnalData,
            eligTechTokyo: {
                homeZone: faiCart.eligibility.box_4g.homeZone || faiCart.eligibility.box_5g.homeZone
            }
        };
    }

    private _addPortabilityForFai(additionnalData: {[key: string]: any},
                                  portability: PortabilityModel,
                                  faiCartId: string): {[key: string]: any} {
        if (portability?.rioCode) {
            additionnalData = {
                ...additionnalData,
                rio : {
                    idPorta: portability.id,
                    noTelephoneAPorter: portability.phoneNumber.replace('/^0/', '+33'),
                    phoneNumber: portability.phoneNumber.replace('/^0/', '+33'),
                    codeRio: portability.rioCode,
                    ...(faiCartId && !this.configService?.data_refconf?.fai?.widget?.active ? {
                        typeDegroupage: 'DT',
                        idPanierWebShop: faiCartId
                    } : {})
                }
            };
        }
        return additionnalData;
    }

    private _addLoginAndViopForFai(additionnalData: {[key: string]: any}, faiCart: FaiCartModel): {[key: string]: any} {
        if (faiCart.selectedLogin && !this.configService?.data_refconf?.fai?.widget?.active) {
            additionnalData.logins = [
                {
                    selected: true,
                    login: faiCart.selectedLogin
                }
            ];
            additionnalData.isLoginReserved = false;

        }
        if (faiCart.selectedVoip && !this.configService?.data_refconf?.fai?.widget?.active) {
            additionnalData.msisdns = [
                {
                    selected: true,
                    id: faiCart.selectedVoip
                }
            ];
            additionnalData.isVoipReserved = false;
        }

        // TODO : Code a supprimer post fix api de ventes : Pblm porta non supprimée en cas de retour arrière
        if (faiCart.selectedVoip && this.configService?.data_refconf?.fai?.widget?.active) {
            additionnalData.msisdns = [
                {
                    id: faiCart.selectedVoip
                }
            ];
        }
        // END TODO

        if (faiCart.selectedVerticalStructure  && !this.configService?.data_refconf?.fai?.widget?.active) {
            additionnalData.structureVerticale = {
                presencePto: faiCart.selectedVerticalStructure.presencePto || false,
                pto: faiCart.selectedVerticalStructure.ptoSelected || null,
                dateOuvertureCommerciale: faiCart.selectedVerticalStructure.dateOuvertureCommerciale
            };
        }
        return additionnalData;
    }

    private _addAppointmentForBox(additionnalData: {[key: string]: any}, appointment: AppointmentModel): {[key: string]: any} {
        if (appointment?.dataToUpdate) {
            additionnalData.informations = appointment.dataToUpdate.information;
            additionnalData.appointement = appointment.dataToUpdate.appointment;
        }
        return additionnalData;
    }

}
