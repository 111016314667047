import { Component, Input, OnInit } from '@angular/core';
import { OPTION_LOCKED_ID } from '@common-modules';
import { Faim, FaimPremium, Plan, PromotionModel, ProductFactory, QuoteModel, Sensation, TYPE_MARKETING } from '@bytel/bytel-sales';
import { ProductRepository } from '@repositories/product.repository';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { SimService } from '@services/checkout/sim.service';

@Component({
    selector: 'tlv-subscription',
    templateUrl: './subscription.component.html',
    styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

    public static BONUS_LABEL: {label: string}[] = [
        {label: ''},
        {label: '1 bonus au choix'},
        {label: 'Tous les bonus inclus au choix'}
    ];

    @Input() product: Plan;

    public TYPE_MARKETING: typeof TYPE_MARKETING = TYPE_MARKETING;
    public isPro: boolean;
    public user: {id: string};
    public isMigratingPlan: boolean = false;
    public hasPromoPrice: boolean = false;
    public isLocked: boolean = false;
    public incitationPromo: PromotionModel;
    public showSimCard: boolean = true;
    public isSas: boolean = false;
    public isFaim: boolean = false;
    public productBonusLabel: string;
    public productLabel: string;
    public hasESimInCart: boolean = false;

    constructor(
        private cartService: CartTeleSalesService,
        private simService: SimService
    ) {
        this.simService.selectedSim$.subscribe(() => {
            this.hasESimInCart = this.simService.hasESimInCart(this.cartService.cartModel);
        });
    }

    public ngOnInit(): void {
        this.isPro = this.cartService.cartModel.isPro ||
        this.cartService.cartModel.getQuote()?.getProductByType('Option')?.gencode === ProductRepository.OPTION_PRO_GENCODE;

        const quote: QuoteModel = this.cartService.cartModel.getQuote();
        const plan: Plan = quote?.getPrincipalProduct<Plan>('Plan');
        this.isSas = ProductFactory.Is(this.product, Sensation, true);
        this.isFaim = ProductFactory.Is(this.product, Faim) || ProductFactory.Is(this.product, FaimPremium);

        this.isLocked = !!quote?.getProductByGencode(OPTION_LOCKED_ID);
        this.showSimCard = !ProductFactory.Is(plan, Faim) && !ProductFactory.Is(plan, FaimPremium);
        this.isMigratingPlan = this.cartService.isMigratingPlan();

        this.hasPromoPrice = this.product.prices.final < this.product.prices.base;
        const incitationsPromo: PromotionModel[] = this.product.promotions.incitation.filter(promo=>promo.message.incitation);
        this.incitationPromo = incitationsPromo.length > 0 ? incitationsPromo[0] : null;
        this.productBonusLabel = SubscriptionComponent.BONUS_LABEL[this.product.data?.plan_bonus_status]?.label;
        this.productLabel = this.isPro ? this.product.proLabel : this.product.label;

        this.hasESimInCart = this.simService.hasESimInCart(this.cartService.cartModel);
    }

    public getQuoteIndex(): number {
        return this.cartService.cartModel.currentQuoteIndex;
    }

}
