import {
    CartModel,
    Product,
    EdpFms,
    OptionPartner,
    PromotionsModel,
    QuoteModel,
    ProductFactory,
} from '@bytel/bytel-sales';
import { FundingMethod } from '@models/payment-method';
import { DisplayEdpFmsOptionModel } from './display-option.model';

export class MainCartModel extends CartModel {
    public cartId: number;
    public fundingMethod: FundingMethod;
    public availableFundingMethods: FundingMethod[] = [];
    public quotes: QuoteModel[] = [];
    public promotions: PromotionsModel = new PromotionsModel([],[],[]);
    public idPerson?: string;

    public getProductQuoteIndex(gencode: string): number {
        let quoteIndex: number = this.quotes.findIndex((quote: QuoteModel)=>quote.products.find((p: Product)=>p.gencode === gencode));
        if (quoteIndex < 0) {
            quoteIndex = 0;
        }
        return quoteIndex;
    }

    public calculateTotals(): void {
        super.calculateTotals();

        const partnerOfferBareTotalPrice: number = this._getPartnerOffersAmount();
        const productsWithPromoLabel: number = this._getProductsWithPromoLabelAmount();

        this.totals.monthly.forever = PromotionsModel.roundPrice(partnerOfferBareTotalPrice + this.totals.monthly.forever);
        this.totals.monthly.forever = PromotionsModel.roundPrice(this.totals.monthly.forever - productsWithPromoLabel);
    }

    /**
     *
     * @param quoteIndex // Index
     * @param force      // Force l'index
     * Suite à la modif de currentQuoteIndex, les méthodes getQuote retournent un quote par défaut.
     * On vérifie que ce quote comporte des produits sinon on recherche un quote avec produit.
     * Sauf si on force à prendre le quoteIndex passé en paramètre (utilisé ici CrossSellPlanComponent pour le panier mixte)
     */
    public getQuote(quoteIndex: number = this.currentQuoteIndex, force: boolean = false): QuoteModel {
        const quote = this.quotes[quoteIndex];
        if (!force) {
            if (!quote || quote?.products?.length === 0) {
                const quoteTmpIndex = this.quotes.findIndex(q => q.products.length > 0);
                if (quoteTmpIndex >= 0) {
                    this.currentQuoteIndex = quoteTmpIndex;
                    return this.quotes[quoteTmpIndex];
                }
                return this.quotes[quoteIndex];
            }
        }
        return this.quotes[quoteIndex];
    }

    public getQuoteIndexByContext(context): number | null {
        let quoteIndex = null;
        Object.keys(this.quotes).forEach(
            (index) => {
                if (this.quotes[index].context instanceof context) {
                    quoteIndex = Number(index);
                }
            }
        );
        return quoteIndex;
    }

    public setFundingMethod(type: string): void {
        this.fundingMethod = this.availableFundingMethods.find(method => method.type === type) ?? this.fundingMethod;
    }

    private _getProductsWithPromoLabelAmount(): number {
        return this.getAllProducts()
            .filter(p=>ProductFactory.Is(p, EdpFms))
            .filter(p=>!!p?.data?.wording_promo && !(p as DisplayEdpFmsOptionModel)?.promoCodePrice)
            .reduce((acc, product: EdpFms)=>acc + (+product.prices.forever ?? 0), 0);
    }

    private _getPartnerOffersAmount(): number {
        return this.getAllProducts()
            .filter(p=>p.data?.type_id === 'offer_partner')
            .reduce((acc, product: OptionPartner)=>acc + (+product.data.code_promo_price ?? 0), 0);
    }

}
