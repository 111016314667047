<div data-cy="order-success">
    <div *ngIf="isLoading; else loadedOrder">
        <div class="columns is-centered">
            <div class="column is-narrow">
                <div class="is-divider has-background-grey-lighter"></div>
                <div class="box is-flat">
                    <div class="box-content">
                        <p class="has-text-centered">
                            <span class="icon-and-text">
                                <span class="icon is-medium">
                                    <i class="tri-shopping-cart"></i>
                                </span>
                                <span class="is-size-5">Récupération des informations sur la commande, un moment....</span>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #loadedOrder>
        <div class="columns is-multiline is-centered">
            <div class="column">
                <section class="hero is-instit">
                    <div class="hero-body">
                        <div class="container">
                            <div class="columns is-multiline is-centered" data-cy="order-success-summary">
                                <div class="column is-12">
                                    <p class="suptitle has-text-centered">Confirmation de la commande</p>
                                    <h1 class="title">
                                        <span class="icon-and-text">
                                            <span class="icon is-huge has-text-white">
                                                <i class="tri-check-circle"></i>
                                            </span>
                                            <span>La commande n° {{orderId}}, passée le {{order.orderCreation | date : 'dd/MM/yyyy' : 'fr'}} à {{order.orderCreation | date : 'H' : 'fr'}}h{{order.orderCreation | date : 'mm'}}<br>a bien été prise en compte</span>
                                        </span>
                                    </h1>
                                </div>
                                <div class="column is-9">
                                    <h2 class="subtitle has-text-centered">Un SMS et un mail de confirmation viennent d'être envoyés à l'adresse <span class="has-text-weight-semibold">{{order.customerEmail}}</span>
                                        <span *ngIf="order?.mainPhoneNumber"> et au numéro suivant: <span class="has-text-weight-semibold">{{order.mainPhoneNumber}}</span></span>
                                    </h2>
                                    <p class="has-text-centered is-size-5 has-text-weight-semibold" *ngIf="order?.checkSum">Clé de Luhn {{order.checkSum}}</p>
                                    <div class="buttons is-centered">
                                        <button data-cy="order-success-end-call" class="button is-primary is-inverted" (click)="endCall()">Terminer session</button>
                                        <button data-cy="order-success-new-order" *ngIf="!orderRecovery" class="button is-primary is-inverted" (click)="addNewOrder()">Saisir une nouvelle commande pour le même client</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div class="column is-8">
                <div class="box">
                    <div class="box-content">
                        <div class="columns is-centered is-multiline">

                            <div class="column is-12">
                                <p class="is-uppercase is-size-6 has-text-centered is-marginless has-text-weight-medium">Bientot chez vous</p>
                                <hr class="is-divider">
                            </div>
                            <ng-container *ngFor="let cartModel of cartModels">
                                <div [ngClass]="{'column is-5': isMultiQuote || cartModel.hasPlan}">
                                    <tlv-subscriptions-summary *ngIf="cartModel.hasPlan" [cartModel]="cartModel.cart"></tlv-subscriptions-summary>
                                </div>
                            </ng-container>
                            <ng-container *ngFor="let cartModel of cartModels">
                                <div [ngClass]="{'column is-5': isMultiQuote || cartModel.hasEquipment}" >
                                    <tlv-equipments-summary *ngIf="cartModel.hasEquipment" [cartModel]="cartModel.cart" showImage="true"></tlv-equipments-summary>
                                </div>
                            </ng-container>

                            <div class="column is-12" *ngIf="installationAddress" data-cy="order-success-installation-address">
                                <p class="has-text-centered">
                                    <span class="icon-and-text">
                                        <span class="icon is-medium">
                                            <i class="tri-marker"></i>
                                        </span>
                                        <span><span class="has-text-weight-medium is-uppercase">Adresse d'installation :</span> {{installationAddress}}</span>
                                    </span>
                                </p>
                            </div>

                            <ng-template #esimDelivery>
                                <div class="box has-background-grey-lighter">
                                    <div class="has-text-centered">
                                        <span class="icon-and-text">
                                            <span class="icon is-medium">
                                                <i class="tri-esim"></i>
                                            </span>
                                            <span>L'eSIM se présente sous forme d'un QR code, scannable en WIFI avec votre téléphone, depuis votre espace client.<br>Vous serez prévenus de sa mise à disposition par email et <span class="is-uppercase">SMS</span>.</span>
                                        </span>
                                    </div>
                                </div>
                            </ng-template>

                            <div class="column is-12" data-cy="order-success-delivery-address" *ngIf="!isMultiQuote; else multiQuoteDeliveries">
                                <div class="box has-background-grey-lighter" *ngIf="isValidDate(order.cart.delivery.deliveryInfos.estimatedDeliveryDate)">
                                    <div class="has-text-centered">
                                        <span class="icon-and-text">
                                            <span class="icon is-medium">
                                                <i class="tri-truck"></i>
                                            </span>
                                            <span>La livraison de votre commande interviendra entre le <span data-cy="order-success-delivery-earliest-date" class="has-text-weight-semibold">{{order.cart.delivery.deliveryInfos.estimatedDeliveryDate | date : 'dd/MM/yyyy'}}</span> et le <span data-cy="order-success-delivery-latest-date" class="has-text-weight-semibold">{{order.cart.delivery.deliveryInfos.lateEstimatedDeliveryDate | date : 'dd/MM/yyyy'}}</span> au plus tard.</span>
                                        </span>
                                    </div>
                                </div>

                                <ng-container *ngIf="hasESimInCart">
                                    <ng-container *ngTemplateOutlet="esimDelivery"></ng-container>
                                </ng-container>
                            </div>

                            <ng-template #multiQuoteDeliveries>
                                <div class="column is-6" *ngFor="let delivery of [order.cart.delivery.mobile, order.cart.delivery.fai]; let isFirst = first;">
            
                                    <ng-container *ngIf="isFirst && hasESimInCart">
                                        <ng-container *ngTemplateOutlet="esimDelivery"></ng-container>
                                    </ng-container>

                                    <div class="box has-background-grey-lighter" *ngIf="delivery && delivery.equipment && isValidDate(delivery.estimatedDeliveryDate) && isValidDate(delivery.lateEstimatedDeliveryDate)">
                                        <div class="has-text-left"> 
                                            <span class="icon-and-text">
                                                <span class="icon is-medium">
                                                    <i class="tri-truck"></i>
                                                </span>
                                                <span>La livraison de vos équipements {{delivery.equipment}} interviendra entre le <span class="has-text-weight-semibold">{{delivery.estimatedDeliveryDate | date : 'dd/MM/yyyy'}}</span> et le <span class="has-text-weight-semibold">{{delivery.lateEstimatedDeliveryDate | date : 'dd/MM/yyyy'}}</span> au plus tard.</span>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>
