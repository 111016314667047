<ng-template #loading>
    <p class="has-text-centered">
        <span class="icon-and-text">
            <span class="icon is-small is-rotating"><i class="tri-logo"></i></span>
            <span>Chargement en cours...</span>
        </span>
    </p>
</ng-template>
<ng-container *ngIf="appointments$ | async; else loading; let events">
    <form [formGroup]="form">
        <div class="is-paddingless">
            <div class="columns is-marginless is-fullwidth is-desktop box-two-slot is-justified-center-desktop">
                <div [ngClass]="{'is-hidden-mobile is-hidden-tablet-only': isMobileAppointmentsBlockVisible}" class="column is-5-widescreen box box-show-slot">
                    <div class="is-shadowless calendar-box">
                        <div class="tabs is-centered">
                            <ul>
                                <li [ngClass]="{'is-invisible' : prevBtnDisabled }">
                                    <a (click)="decrement()">
                                        <span class="icon is-small"><i class="tri-arrow-left"></i></span>
                                    </a>
                                </li>
                                <li>
                                    {{ viewDate | calendarDate:(view + 'ViewTitle')}}
                                </li>
                                <li [ngClass]="{'is-invisible' : nextBtnDisabled }">
                                    <a (click)="increment()">
                                        <span class="icon is-small"><i class="tri-arrow-right"></i></span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div [ngSwitch]="view">
                            <mwl-calendar-month-view
                                *ngSwitchCase="'month'"
                                [viewDate]="viewDate"
                                [activeDay]="selectedDay"
                                [events]="events"
                                [refresh]="refreshSubject"
                                [cellTemplate]="customCellTemplate"
                                (dayClicked)="handleSelectedDay($event.day, true)"
                                (beforeViewRender)="beforeMonthViewRender($event)"
                                [weekStartsOn]="weekStartsOn"
                            >
                            </mwl-calendar-month-view>
                            <!-- <div class="columns is-marginless">
                                <div class="column">
                                    <div class="badge-and-text">
                                        <p class="is-italic">Nombre de créneaux disponible par jour</p>
                                        <span class="badge is-level has-background-tertiary">n</span>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="is-hidden-desktop">
                        <ng-container *ngTemplateOutlet="rdvLivraisonMessageValidation"></ng-container>
                    </div>
                </div>

                <div [ngClass]="{'is-hidden-mobile is-hidden-tablet-only': !isMobileAppointmentsBlockVisible}" class="column is-4-widescreen box is-marginless is-paddingless box-choose-slot">
                    <div
                        class="appointments-header columns is-mobile is-marginless is-paddingless is-vcentered has-background-grey-light">
                        <div class="column is-narrow-mobile is-hidden-desktop">
                            <a (click)="closeMobileAppointmentsBlock()" class="link">Retour</a>
                        </div>
                        <div class="column">
                            <p class="text is-1 has-text-weight-bold has-text-centered">{{ headerTextAppointment }}</p>
                        </div>
                    </div>
                    <div *ngIf="dayAppointments; else noSelectedDate">
                        <div class="options">
                            <div class="appointment is-flex columns is-mobile is-marginless is-multiline is-fullwidth">
                                <div *ngFor="let dayAppointment of dayAppointments"
                                     class="day-appointment-control control column is-6">
                                    <input *ngIf="!callOut"
                                        formControlName="appointment"
                                        class="input is-checkradio is-hidden"
                                        type="radio"
                                        [id]="dayAppointment.id+'-'+dayAppointment.start.getTime()"
                                        [value]="dayAppointment.meta">
                                    <label class="text is-3 paragraph"
                                           (click)="setAppointment(dayAppointment)"
                                           [for]="dayAppointment.id+'-'+dayAppointment.start.getTime()">{{ dayAppointment.title }}</label>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngTemplateOutlet="rdvLivraisonMessageValidation"></ng-container>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <ng-template #customCellTemplate let-day="day" let-locale="locale">
        <div class="cal-cell-top">
            <span class="cal-day-number"
                  [ngClass]="{'active': day.badgeTotal > 0}">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
            <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
        </div>
    </ng-template>
    <ng-template #noSelectedDate>
        <div *ngIf="!appointment && !currentAppointmentError" class="no-selected-date-body is-flex rows is-justified-center is-vcentered is-marginless">
            <div>
                <span class="icon is-medium">
                    <i class="tri-calendar"></i>
                </span>
            </div>
            <p class="has-text-centered text is-1 has-text-weight-bold">Vous n'avez pas encore sélectionné de date dans
                le calendrier</p>
        </div>
        <div class="error-block is-flex rows is-marginless" *ngIf="appointment && !!currentAppointmentError">
            <div class="filler is-flex is-justified-center is-vcentered">
                <span class="icon is-large">
                    <i class="tri-calendar"></i>
                </span>
            </div>
            <div
                class="resume-notification notification is-small has-body is-danger">
                <div class="icon has-text-tertiary">
                    <i class="tri-exclamation-circle"></i>
                </div>
                <div class="body">
                    <p class="text is-1" [innerHTML]="currentAppointmentError | trustHtml"></p>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #rdvLivraisonMessageValidation>
        <div *ngIf="appointment && !currentAppointmentError && !callOut"
             class="resume-notification notification is-small has-body is-info">
            <div class="icon has-text-tertiary">
                <i class="tri-calendar"></i>
            </div>
            <div class="body">
                <p class="text is-1" bytelTranslate="calendar.rdv_livraison.message_validation">
                    <span *ngIf="isPreselectedDate">Votre rendez-vous présélectionné: </span>
                    <span *ngIf="!isPreselectedDate">Vous avez choisi : le </span>
                    <span class="has-text-weight-bold">{{ appointment.start | date : 'EEEE dd/MM'}}</span>
                    entre
                    <span
                        class="has-text-weight-bold">{{ appointment.start | date : 'HH:mm'}}</span>
                    et
                    <span
                        class="has-text-weight-bold">{{ appointment.end | date : 'HH:mm'}}</span></p>
                <p *ngIf="isPreselectedDate">Vous pouvez cliquer sur une autre date pour le modifier.</p>
            </div>
        </div>
    </ng-template>
</ng-container>
