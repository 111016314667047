import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService, GcpRequest, Oauth2ResourcesService } from '@common-modules';
import { IGcpResponse, VALID_CODE_RETOUR_GCP } from '@interfaces/api/gcp/gcpResponse';
import { IControlerSirenControleSirenOut } from '@interfaces/api/siren/controler-siren-controle-siren-out';
import { ControlerSirenRepresentantLegal } from '@interfaces/api/siren/controler-siren-representant-legal';
import { IValidationMail } from '@interfaces/api/validation-mail.interface';
import { AddressModel } from '@models/cart/address.model';
import { PortabilityModel } from '@models/cart/portability.model';
import { CompanyModel } from '@models/customer/company.model';
import { AddressValidationModel } from '@models/validators/address-validation.model';
import { EmailValidationModel } from '@models/validators/email-validation.model';
import { PortaValidationModel } from '@models/validators/porta-validation.model';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { USER_TITLES } from '../constants/customer';
import bind from '../helper/decorators/bind';
import CiviliteEnum = ControlerSirenRepresentantLegal.CiviliteEnum;

@Injectable({
    providedIn: 'root'
})
export class ValidatorsRepository {

    constructor(
        private oauth2ResourcesService: Oauth2ResourcesService,
        private configService: ConfigurationService,
        private httpClient: HttpClient
    ) { }

    public portaValidationFai(phoneNumber: string,
                              rioCode: string,
                              faiCartId: string,
                              porta?: PortabilityModel): Observable<PortaValidationModel>{
        if (phoneNumber.startsWith('0')){
            phoneNumber = phoneNumber.replace('0', '+33');
        }
        return this.oauth2ResourcesService.useSalesApi().ventes().controlePortabilite()
            .post({
                noTelephoneAPorter: phoneNumber,
                codeRio: rioCode,
                idPanierWebShop: faiCartId,
                typeDegroupage: 'DT',
                ...(porta ? {idPorta: porta.id} : {})
            }).pipe(
                map((resPorta) =>
                    new PortaValidationModel({
                        status: resPorta.statut,
                        id: resPorta.idPorta,
                        phoneNumber,
                        rioCode,
                    })
                )
            );
    }

    public portaValidation(phoneNumber: string,rioCode: string): Observable<PortaValidationModel>{
        if (phoneNumber.startsWith('0')){
            phoneNumber = phoneNumber.replace('0', '+33');
        }
        return this.oauth2ResourcesService.useSalesApi().ventes().controlePortabilite().post({
            noTelephoneAPorter: phoneNumber,
            codeRio: rioCode,
        }).pipe(
            map((porta) => new PortaValidationModel({
                status: porta.status,
                rioCode,
                phoneNumber
            }))
        );
    }

    public mailValidation(email: string): Observable<EmailValidationModel> {
        return this.oauth2ResourcesService
            .useSalesApi()
            .validationEmail()
            .setParams({
                mail: email
            })
            .get<IValidationMail>()
            .pipe(
                map(data => new EmailValidationModel({
                    userGeneric: data.utilisateurGenerique,
                    correctEmailSyntax: data.syntaxeEmailCorrecte,
                    validEmail: data.emailValide,
                    domainExist : data.domaine?.domaineExistant,
                    temporaryEmail: data.emailTemporaire,
                    freeEmail: data.emailGratuit,
                    emailContact: data.emailContact,
                    domainBbox: data.domaine?.domaineBbox
                })),
                catchError(() => of(new EmailValidationModel({
                    userGeneric: false,
                    domainExist: true,
                    correctEmailSyntax: true,
                    validEmail: true,
                    temporaryEmail: false,
                    freeEmail: false,
                    emailContact: false,
                    domainBbox: false
                })))
            );
    }

    public sirenValidation(siren: string): Observable<CompanyModel> {
        return this.oauth2ResourcesService
            .useSalesApi()
            .setLocalService()
            .controleSiren(siren)
            .addCache()
            .get<IControlerSirenControleSirenOut>()
            .pipe(map(this._createSirenValidationModel));
    }

    public addressValidation(address: AddressModel): Observable<AddressValidationModel> {
        address.streetNumber = address?.streetNumber?.replace(/_none/, '0');
        const request: GcpRequest = {
            numero: address.streetNumber,
            voie: address.street,
            complement: address.complement,
            codePostal: address.postalCode,
            ville: address.city,
            origineST: 'LABOUTIQUEBYTEL'
        };
        return this.oauth2ResourcesService
            .useSalesApi()
            .ventes()
            .gcp()
            .post(request)
            .pipe(
                map(this._createAddressValidationModel),
                catchError(() => of(new AddressValidationModel({...address,valid:false})))
            );
    }

    public rnaNumberValidation(rnaNumber: string): Observable<HttpResponse<object>> {
        return this.httpClient.get(`${this.configService.data_refconf.fai.gouv.control_rna_url}${rnaNumber}`, {observe: 'response'});
    }

    @bind
    private _createAddressValidationModel(dataAddress: IGcpResponse): AddressValidationModel {
        return new AddressValidationModel({
            postalCode: dataAddress.codePostal,
            city: dataAddress.ville,
            street: dataAddress.voie,
            streetNumber: dataAddress.numero,
            valid: VALID_CODE_RETOUR_GCP.includes(dataAddress.codeRetour.codeRetour)
        });
    }

    @bind
    private _createSirenValidationModel(dataSiren: IControlerSirenControleSirenOut): CompanyModel{
        return new CompanyModel({
            ...(dataSiren.adresseDeclaree ? {address: new AddressModel({
                postalCode: dataSiren.adresseDeclaree.cp,
                city: dataSiren.adresseDeclaree.ville,
                street: dataSiren.adresseDeclaree.voie,
                streetNumber: dataSiren.adresseDeclaree.num
            })} : {}),
            socialCapital: dataSiren.codeEffectif,
            socialReason: dataSiren.raisonSociale,
            creationDate: dataSiren.dateCreation ? new Date(dataSiren.dateCreation) : null,
            legalForm: dataSiren.formeJuridique,
            codeApeNaf: dataSiren.codeApeNaf,
            siren: dataSiren.noSiren,
            legalRepresentative: dataSiren.representantLegal ? {
                gender:  dataSiren.representantLegal.civilite ?
                    CiviliteEnum.M ===  dataSiren.representantLegal.civilite ? USER_TITLES.mister : USER_TITLES.missis
                    : null,
                birthDate: dataSiren.representantLegal.dateNaissance ? new Date(dataSiren.representantLegal.dateNaissance) : null,
                firstName: dataSiren.representantLegal.prenom,
                lastName: dataSiren.representantLegal.nom
            } : null,
        });
    }
}
