import { DOCUMENT } from '@angular/common';
import { AfterViewChecked, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Activation, Equipment, Fai, FaiBox, ProductFactory, PromotionModel, QuoteContextFaiModel } from '@bytel/bytel-sales';
import { OrderModel } from '@models/order/order.model';
import { ProductRepository } from '@repositories/product.repository';
import { CatalogService } from '@services/catalog.service';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { CartService } from '@services/checkout/cart.service';
import { CustomerService } from '@services/customer/customer.service';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import { PromotionsService } from '@services/promotions.service';
import { SalesForceService } from '@services/salesforce.service';
import { ValidatorsService } from '@services/validators.service';
import { Observable, Subscription, of } from 'rxjs';
import { delayWhen, finalize, mergeMap } from 'rxjs/operators';
import bind from '../../../../helper/decorators/bind';
import { EQUIPMENT_FILTER_LABELS } from 'src/app/constants/fai';
import { IFaiBundle } from '../offers.component';

@Component({
    selector: 'tlv-offer-details',
    templateUrl: './offer-details.component.html',
    styleUrls: ['./offer-details.component.scss']
})
export class OfferDetailsComponent implements OnInit, OnDestroy, AfterViewChecked {
    @Input()
    public isPro: boolean;
    @Input()
    public offer: IFaiBundle;
    @Output()
    public offerChange: EventEmitter<IFaiBundle> = new EventEmitter<IFaiBundle>();
    @Input()
    public isDisabled: boolean;
    @Input()
    public canBeQuoteMixed: boolean;
    public ocBox4G: string[];
    public loader: boolean = false;
    public ocBox5G: string[];
    public manualPromos: PromotionModel[] = [];
    public formPromo: FormGroup = new FormGroup({
        enablePromotions: new FormControl(false),
        promotion: new FormControl<{value: string; disabled: boolean} | null>({value: '', disabled: true})
    });
    public EQUIPMENT_FILTER_LABELS = EQUIPMENT_FILTER_LABELS;
    public rnaForm: FormGroup;
    public isRnaValid: boolean = true;
    public controlRnaInProgress: boolean = false;
    public isFaimUnlimited: boolean = false;
    public scrollOnOffer: boolean = false;
    public splitedEquipments: string[] = [];
    public productAssociated: Equipment;
    public _orderRecovery: OrderModel;
    private _subscriptions: Subscription[] = [];


    constructor(private readonly faiEligibility: FaiEligibilityService,
                private readonly cartService: CartService,
                private readonly cartTeleSalesService: CartTeleSalesService,
                private readonly router: Router,
                private readonly promotionsService: PromotionsService,
                private readonly validatorsService: ValidatorsService,
                private readonly customerService: CustomerService,
                private readonly catalogService: CatalogService,
                private readonly salesForceService: SalesForceService,
                @Inject(DOCUMENT) private readonly document: Document
    ) {
        this.ocBox4G = this.faiEligibility.currentCart.eligibility.box_4g?.offres ?? [];
        this.ocBox5G = this.faiEligibility.currentCart.eligibility.box_5g?.offres ?? [];
        this._orderRecovery = this.salesForceService.prefilledInfo.order;
    }

    public ngOnInit(): void {
        this.splitedEquipments = this.EQUIPMENT_FILTER_LABELS[this.offer.fai.equipment]?.split(/\set\s/) || [];
        this.isDisabled = this.isDisabled && this.offer.fai.range !== 'bbox_tv_plus';
        this.isFaimUnlimited = ProductFactory.Is(this.offer.fai, 'FaimUnlimited');
        if (this.isFaimUnlimited && this.offer.fai.data?.produit_prioritaire) {
            this.catalogService.getProductByGencode<Equipment>(this.offer.fai.data?.produit_prioritaire).subscribe((p: Equipment) => {
                this.productAssociated = p;
                this.splitedEquipments = [p.label];
            });
        }
        this._subscriptions.push(
            this.formPromo.get('enablePromotions').valueChanges.subscribe(this._onEnablePromotionsChange),
            this.formPromo.get('promotion').valueChanges.subscribe(this._onPromotionChange),
            this.promotionsService.getPromotions(this.offer.cart).subscribe((promotions) => {
                this.manualPromos = promotions.manual;
                this._selectPromotionFromOrderRecovery();
            }));

        if (this.offer?.fai?.isAssos) {
            this.rnaForm = new FormGroup({
                rna: new FormControl('', this._rnaValidators)
            });
            this.isRnaValid = false;
            this._subscriptions.push(this.rnaForm.get('rna').valueChanges.subscribe(this._onRnaValueChange));
        }
    }

    public ngAfterViewChecked(): void {

        if (this.scrollOnOffer) { return; }

        const scrollElt = this.document.defaultView.document.querySelector(`#${'offer-detail-' + this.offer.fai.data.gencode}`);
        if (scrollElt) {
            scrollElt.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
            this.scrollOnOffer = true;
        }
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

    public removePromotion(): Observable<void> {
        if (this.offer.cart.promotions.manual.length) {
            return this.cartService.removeManualPromotion(this.offer.cart, this.offer.cart.promotions.manual[0]);
        }
        return of(null);
    }

    public addPromotion(promo: PromotionModel): void {
        this.loader = true;
        of(this.offer.cart)
            .pipe(delayWhen((cart) => this.cartService.addManualPromotion(cart, promo)))
            .subscribe((cart) => {
                this.offer = {
                    fai: cart.getQuote().getProductByType(Fai),
                    activation: cart.getQuote().getProductByType(Activation),
                    location: cart.getQuote().getProductByType(FaiBox),
                    skus: this.offer.skus,
                    cart
                };
                this.loader = false;
                this.offerChange.emit(this.offer);
            });
    }

    public addToCart(): void {
        this.cartTeleSalesService.clearQuoteFai();
        if (!this.canBeQuoteMixed) {
            this.cartTeleSalesService.clearQuoteAcquisition();
        }

        const indexQuoteFai = this.cartTeleSalesService.getQuoteIndexByContext(QuoteContextFaiModel);
        if (indexQuoteFai === null) {
            this.cartTeleSalesService.addQuote(new QuoteContextFaiModel());
        }

        let add = this.cartTeleSalesService
            .addProduct(ProductRepository.Clone(this.offer.fai), this.cartTeleSalesService.getQuoteIndexByContext(QuoteContextFaiModel))
            .pipe(
                mergeMap(() => this.isPro ? this.catalogService.getProductByGencode(ProductRepository.OPTION_PRO_GENCODE) : of (null)),
                delayWhen(p => p ? this.cartTeleSalesService.addProduct(p) : of(null))
            );

        if (this.offer.cart.promotions.manual.length) {
            add = add.pipe(delayWhen(() =>
                this.cartTeleSalesService.addManualPromotion(this.offer.cart.promotions.manual[0])
            ));
        }

        add.subscribe(() => {
            this._addFmsRecovery();
            this.router.navigate(['panier']);
        });
    }

    public checkRnaValidity(): void {
        const rnaValue = this.rnaForm.get('rna')?.value as string;
        this.controlRnaInProgress = true;

        this._subscriptions.push(this.validatorsService.rnaNumberValidation(rnaValue).pipe(finalize(() => {
            this.controlRnaInProgress = false;
        })).subscribe({next: () => {
            /* if (!response) {
                this.rnaForm.get('rna').setErrors({ invalid: true });
                return;
            }*/
            this.customerService.setRna(rnaValue);
            this.isRnaValid = true;
        }, error: () => {
            this.isRnaValid = true;
            // this.rnaForm.get('rna').setErrors({ invalid: true });
        }}));
    }

    private _addFmsRecovery(): void {
        if (this._orderRecovery) {
            const fms: Activation = this.cartTeleSalesService.cartModel
                .getQuote(this.cartTeleSalesService.getQuoteIndexByContext(QuoteContextFaiModel))?.getProductByType('Activation');

            if (fms) {
                fms.priceType = this._orderRecovery.cart.payment.initialFmsPaymentType;
            }
        }
    }
    private _selectPromotionFromOrderRecovery(): void {
        const orderRecoveryPromoId: string = this._orderRecovery?.fai?.offer?.manualPromotionId;
        const appliedPromotion: PromotionModel = this.manualPromos.find((p: PromotionModel) => p.addProduct === orderRecoveryPromoId);

        if (orderRecoveryPromoId && appliedPromotion) {
            this._onEnablePromotionsChange(true);
            this.formPromo.patchValue({
                enablePromotions: true,
                promotion: appliedPromotion.id
            });
        }
    }

    @bind
    private _onEnablePromotionsChange(value: boolean): void {
        const checkBox = this.formPromo.get('promotion');
        checkBox.setValue(null);
        if (value) {
            checkBox.enable();
        } else {
            checkBox.disable();
        }
    }

    @bind
    private _onPromotionChange(value: number): void {
        if (value){
            this.removePromotion().subscribe(()=>this.addPromotion(this.manualPromos.find((promo)=>promo.id === value)));
        } else {
            this.removePromotion();
        }
    }

    @bind
    private _onRnaValueChange(value: string): void {
        this.isRnaValid = false;
        this.rnaForm.get('rna').patchValue(value.toUpperCase(), { emitEvent: false});
    }

    @bind
    private _rnaValidators(control: AbstractControl): ValidationErrors | null {

        let rnaMask: RegExp;
        if (!control.value) {
            return Validators.required(control);
        }

        if (control.value.startsWith('W')) {
            rnaMask = new RegExp(/^[a-zA-Z\d]{10}$/);
        } else {
            rnaMask = new RegExp(/^[a-zA-Z\d-]{7,14}$/);
        }

        return Object.assign({},
            Validators.required(control), Validators.pattern(rnaMask)(control));
    }
}
