import { Injectable } from '@angular/core';
import { BasicObject } from '../interfaces/services.interface';

class StorageConf {
    [index: string]: string[];
}

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    public localKeysConf: StorageConf = {configurations: []};
    public sessionKeysConf: StorageConf = {};
    public prefix: string = 'bytel';
    public type: string;

    public clear(): void {
        this.clearTag();
    }

    public clearTag(tag: string = null): void {
        this._cleanStorage(this.sessionKeysConf, tag);
        this._cleanStorage(this.localKeysConf, tag);
    }

    public get(key: string, prefix: boolean = true): any {
        let item: BasicObject = null;
        const storageItem: BasicObject = this._getData(key, prefix);

        if (storageItem !== null) {
            try {
                item = storageItem.hasOwnProperty('data') ? storageItem.data : storageItem;
                const now: number = new Date().getTime();
                if (storageItem.expiresTimestamp && storageItem.expiresTimestamp < now) {
                    item = null;
                    this.remove(key);
                }
            } catch (e) {
                console.error(e);
            }
        }
        return item;
    }

    public getExpires(key: string, prefix: boolean = true): any {
        const storageItem: BasicObject = this._getData(key, prefix);
        if (storageItem !== null) {
            return storageItem?.expiresTimestamp;
        }
    }

    public remove(key: string, prefix: boolean = true): void {
        const storageKey: string = prefix ? `${this.prefix}_${key}` : key;
        this._getStorageInstance(key).removeItem(storageKey);
    }

    public set(key: string, value: Record<string, unknown>, prefix: boolean = true, expires: number = null): void {
        // TODO check if valid storage key
        const storageKey: string = prefix ? `${this.prefix}_${key}` : key;

        const data: string = JSON.stringify({
            data: value,
            expiresTimestamp: expires
        });

        this._getStorageInstance(key).setItem(storageKey, data);
    }

    public addLocalKey(data: BasicObject): StorageConf {
        this.localKeysConf = {
            ...this.localKeysConf,
            ...data
        };
        return this.localKeysConf;
    }

    public addSessionKey(data: BasicObject): StorageConf {
        this.sessionKeysConf = {
            ...this.sessionKeysConf,
            ...data
        };
        return this.sessionKeysConf;
    }

    protected _getStorageInstance(key: string): Storage {
        let storage: Storage;
        if (Object.keys(this.sessionKeysConf).includes(key)) {
            storage = sessionStorage;
        } else {
            storage = localStorage;
        }
        return storage;
    }

    private _cleanStorage(storageConf: StorageConf, tag: string = null): void {
        Object.keys(storageConf).forEach(key => {
            if (!tag || storageConf[key].includes(tag)) {
                this.remove(key);
                this.remove(key, false);
            }
        });
    }

    private _getData(key: string, prefix: boolean = true): BasicObject {
        const storageKey: string = prefix ? `${this.prefix}_${key}` : key;
        const data: string = this._getStorageInstance(key).getItem(storageKey);

        if (data !== null) {
            try {
                return JSON.parse(data);
            } catch (e) {
                console.error(e);
            }
        }
        return null;
    }
}
