import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// @dynamic
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[bytelNavigateTo]',
})
export class NavigateToDirective implements OnInit {
    @Input('bytelNavigateTo') url: string;
    private routeUrl: string;

    public constructor(
        @Inject(DOCUMENT) private document: Document,
        private route: Router,
        protected el: ElementRef
    ) { }

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent): void {
        event.preventDefault();
        this.route.navigateByUrl(this.routeUrl).catch(() => this.document.defaultView.location.href = this.routeUrl);
    }

    public ngOnInit(): void {
        if (this.url) {
            this.routeUrl = this.url.replace(this.document.location.origin, '');
            this.el.nativeElement.href = this.routeUrl;
        }
    }
}
