import { Model } from './model';

export class EquipmentDetailsModel extends Model {

    public auto_focus: boolean;
    public battery: string;
    public battery_capacity: string;
    public battery_removable: string;
    public battery_standby_time: string;
    public battery_talk_time: string;
    public capacity_available: string;
    public double_camera: boolean;
    public multiple_sim: boolean;
    public os_name: string;
    public os_version: string;
    public processor_core: string;
    public repairNote: number;
    public repairNotePdf: number;
    public processor_speed: string;
    public crossSellsProducts: any[];
    public weight: string;
    public bonus_recovery: number;
    public recovery_to_date: string;
    public recovery_from_date: string;
    public limbs_sar: string;
    public screen_size: string;
    public resolution_sensor: string;


    constructor(data: Partial<EquipmentDetailsModel>){
        super(data);
    }

}
