import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterObj',
    pure: false
})
export class FilterObjPipe implements PipeTransform {

    public transform<T>(
        items: T[],
        filter: {[index: string]: string},
        strict: boolean | {[index: string]: boolean} = false,
        pathsToExclude: string = '',
        caseSensitive: boolean = false ): T[] {

        const filtersKey: string[] = Object.keys(filter);
        if (!items || !filter || !filtersKey.length) {
            return items;
        }
        if (filtersKey.length === 1 && !filtersKey.every(key => filter[key] !== null)) {
            return items;
        }

        filter = this._omit(filter, pathsToExclude);

        return items.filter(item => this._filtredCondition<T>(item, filter, caseSensitive, strict));
    }

    /**
     * Creates an object composed of the own enumerable property paths of object that are not omitted.
     * Arguments
     */
    private _omit(object: {[index: string]: string}, paths: string = ''): {[index: string]: string}{
        const clone = {...object}; // shallow copy
        paths.split(',').forEach((item) => {

            if (clone.hasOwnProperty(item))
            {delete clone[item];}
        });

        return clone;
    }

    private _filtredCondition<T>(
        item: T,
        filter: {[index: string]: string},
        caseSensitive: boolean,
        strict: boolean | {[index: string]: boolean}): boolean {
        for (const key in filter) {
            if (filter[key] && !item[key]) {
                return false;
            }
            if ((item[key] || item[key] === '') && this._innerCondition(key,item,filter,caseSensitive,strict)) {
                return false;
            }
        }
        return true;
    }

    private _innerCondition<T>(key: string,
                               item: T,
                               filter: {[index: string]: string},
                               caseSensitive: boolean,
                               strict: boolean | {[index: string]: boolean}): boolean{

        const value: string = (caseSensitive) ? item[key] : item[key].toUpperCase();
        const filterValue: string = (caseSensitive) ? filter[key] : filter[key]?.toString()?.toUpperCase();
        if ((!strict && typeof (strict) != 'object') || (typeof (strict) == 'object' && !strict[key])) {
            return !value.includes(filterValue) && value !== filterValue;
        } else {
            return !!(value !== filterValue && filterValue);
        }
    }
}
