import { Injectable } from '@angular/core';
import { Oauth2ResourcesService } from '@common-modules';
import { IGlobalScoring } from '@interfaces/scoring.interface';
import { ScoringModel } from '@models/scoring.model';
import { classToPlain } from 'class-transformer';
import {forkJoin, Observable, of} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import bind from '../helper/decorators/bind';
import { ScoringStorage } from './storages/scoring.storage';
import { Resultat } from '@bytel/pt-ihm-api-egide-controle-risque-vente-demander-offres-autorisees';
import { ControleRisqueVente } from '@bytel/pt-ihm-api-egide-controle-risque-vente-demander-financements-autorises';

@Injectable({
    providedIn: 'root'
})
export class SalesRiskControlRepositoryRepository {

    protected _koBloquant = ['KO', 'KO_SAUF_ACTION_REALISEE'];
    protected _koNonBloquant = [
        'NON_DETERMINANT', 'OK', 'OK_AVEC_INFORMATION'
    ];
    constructor(
        private oauth2Resource: Oauth2ResourcesService,
    ) {}


    public checkAutorisationVente(idPanier: string): Observable<ScoringModel> {
        return this._autorisationVente(idPanier)
            .pipe(
                catchError((err) => {
                    console.warn(err);
                    return of(err?.error?.global ?
                        err?.error : {
                            global: {},
                            detaille: {}
                        });
                }),
                map( (data: ControleRisqueVente) => this._createScoringDetailedModel(data))
            );
    }
    // @ts-ignore
    public checkScoring(idPU: string, cartId: string): Observable<ScoringModel> {
        if (cartId) {
            return this.checkAutorisationVente(cartId);
        }
        const financementsAutorises = this._financementsAutorises(idPU);
        const offresAutorisees = this._offresAutorisees(idPU);
        return forkJoin([financementsAutorises,offresAutorisees])
            .pipe(
                catchError((err) => {
                    console.warn(err);
                    return of(err?.error?.global ?
                        err?.error : {
                            global: {},
                            detaille: {}
                        });
                }),
                map(([financement, offres]) => this._createScoringModel(financement, offres))
            );
    }



    public saveScoring(data: ScoringModel): void {
        const oneHour: number = 3600 * 1000;
        ScoringStorage.setItem(ScoringStorage.KEYS.SCORING, classToPlain(data), Date.now() + oneHour * 24);
    }

    public clear(): void {
        ScoringStorage.removeItem(ScoringStorage.KEYS.SCORING);
    }

    private _createScoringDetailedModel(autorisationVente: ControleRisqueVente): ScoringModel {
        const detailedRes: Partial<Resultat[]> = autorisationVente.resultats
            .reduce((result, current) =>
                // eslint-disable-next-line max-len
                current.type === 'ResultatGlobal' && current.resultat.search('KO') >= 0 && current.champApplication.type === 'ParLigneMarcheMetier'
                    ? [
                        ...result, {
                            // eslint-disable-next-line max-len
                            type: (current.champApplication.type === 'ParLigneMarcheMetier') ? current.champApplication?.ligneMarcheMetier : '',
                            edp: !!(current?.actions && current.actions.find((action) => action.action === 'RETRAIT_EDP')),
                            message: current.message
                        }
                    ]
                    : result,  []);

        return new ScoringModel({
            detailed: detailedRes
        });
    }

    @bind
    private _createScoringModel(financement: ControleRisqueVente, offres: ControleRisqueVente): ScoringModel {

        const koNonBloquant: Resultat[] = [];
        const koBloquant: Resultat[] = [];
        const koEdp: Resultat[] = [];

        offres.resultats.map(
            (res: Resultat) =>  (this._koNonBloquant.includes(res.resultat)) ? koNonBloquant.push(res) : koBloquant.push(res)
        );

        financement.resultats.map(
            (res: Resultat) => (res.perimetre === 'EDP' && this._koBloquant.includes(res.resultat)) && koEdp.push(res)
        );

        return new ScoringModel({
            global: {
                ...this._createGlobalScoreNode(offres, financement)
            },
        });
    }

    private _createGlobalScoreNode(data: ControleRisqueVente, financement: ControleRisqueVente): Partial<IGlobalScoring>
    {
        const types: {[key: string]: string} = {
            forfaitData: 'faim',
            voixSansEngagement: 'basic',
            voixAvecEngagement: 'premium',
            boxFixe: 'fai',
            xGBox: 'box',
            autre: 'autre',
        };

        const incompatibilitySalesRisk: Partial<{
            basic?: Resultat;
            premium?: Resultat;
            faim?: Resultat;
            fai?: Resultat;
            edp?: Resultat;
        }> = {};

        data.resultats.map(
            (res: Resultat) => {
                if (res.champApplication.type === 'ParLigneMarcheMetierParCategorie'
                    && res.resultat.search('KO') >= 0) {
                    const category = types[res.champApplication.categorie];
                    incompatibilitySalesRisk[category] = res;
                    return incompatibilitySalesRisk[category];
                }
            }
        );

        financement.resultats.map(
            (res: Resultat) => {
                if (res.perimetre === 'EDP' && this._koBloquant.includes(res.resultat)) {
                    incompatibilitySalesRisk.edp = res;
                }
            }
        );
        return {
            incompatibilitySalesRisk,
        };
    }

    private _financementsAutorises(idPU: string): Observable<ControleRisqueVente> {
        return this.oauth2Resource
            .controleRisquesVente()
            .financementsAutorises()
            .setLocalService()
            .useSalesApi()
            .post({
                objetControle: {
                    id: idPU,
                    type: 'PERSONNE'
                }
            });
    }
    private _offresAutorisees(idPU: string): Observable<ControleRisqueVente> {
        return this.oauth2Resource
            .controleRisquesVente()
            .offresAutorisees()
            .setLocalService()
            .useSalesApi()
            .post({
                objetControle: {
                    id: idPU,
                    type: 'PERSONNE'
                }
            });
    }
    private _autorisationVente(idPanier: string): Observable<ControleRisqueVente> {
        return this.oauth2Resource
            .controleRisquesVente()
            .autorisationVente()
            .setLocalService()
            .useSalesApi()
            .post({
                enregistrementCommande: true,
                objetControle: {
                    id: idPanier,
                    type: 'PANIER'
                }
            });
    }
}
