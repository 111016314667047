import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Fai, Plan, Product, ProductFactory } from '@bytel/bytel-sales';
import { BasicObject, REGEXS } from '@common-modules';
import { regions } from '@components/checkout/step/customer-info/regions';
import { CustomerProGpDetailsModel } from '@interfaces/customer.interface';
import { CustomerDetailsModel } from '@models/customer/customer-details.model';
import { CustomerProDetailsModel } from '@models/customer/customer-pro-details.model';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { SalesService } from '@services/checkout/sales.service';
import { SimService } from '@services/checkout/sim.service';
import { StepperService } from '@services/checkout/stepper.service';
import { CustomerService } from '@services/customer/customer.service';
import { ValidatorsService } from '@services/validators.service';
import { format, parseISO } from 'date-fns';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, delayWhen, filter, finalize, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { USER_TITLES } from '../../../../constants/customer';
import bind from '../../../../helper/decorators/bind';
import { CustomFormControl } from '../../../../helper/form-control/custom-form-control';

@Component({
    selector: 'tlv-customer-info-step',
    templateUrl: './customer-info.component.html',
    styleUrls: ['./customer-info.component.scss']
})
export class CustomerInfoComponent implements OnInit {

    public showComplexity: boolean = false;
    public userTitles: typeof USER_TITLES = USER_TITLES;
    public regions = regions;
    public isLoading: boolean = false;
    public customerDetails: CustomerProGpDetailsModel;
    public hasProProduct: boolean = false;
    public form: FormGroup = new FormGroup({
        email: new CustomFormControl(
            '',
            [
                Validators.required,
                Validators.pattern(REGEXS.EMAIL_REGEXP)
            ],
            [this._emailApiFeedbackValidator]
        ),firstname: new CustomFormControl(
            '',
            [
                Validators.required,
                Validators.pattern(REGEXS.NAME_REGEXP)
            ]
        ),lastname: new CustomFormControl(
            '',
            [
                Validators.required,
                Validators.pattern(REGEXS.NAME_REGEXP),
                Validators.minLength(2),
                Validators.maxLength(32)
            ]
        ),birthDate: new CustomFormControl(
            '',
            [
                Validators.required,
                Validators.pattern(REGEXS.DATE_REGEXP),
                this._birthDateValidator,
            ]
        ),birthDepartment: new CustomFormControl(
            '',
            [Validators.required]
        ),gender: new CustomFormControl(
            '',
            [Validators.required]
        ),phone: new CustomFormControl(
            '',
            [
                Validators.required,
                Validators.pattern(REGEXS.MOBILE_REGEXP),
                Validators.minLength(10),
                Validators.maxLength(10)
            ]
        )
    });
    public mock: boolean;
    public isValidatingAutomaticStep: boolean = false;

    constructor(private validatorsService: ValidatorsService,
                private customerService: CustomerService,
                private stepperService: StepperService,
                private readonly activatedRoute: ActivatedRoute,
                private cartTeleSalesService: CartTeleSalesService,
                @Inject(DOCUMENT) private document: Document,
                private simService: SimService,
                private salesService: SalesService) {
        this.mock = !!document.defaultView.window.ConfigInitial.mock;

    }

    public ngOnInit(): void {
        this.hasProProduct = this.cartTeleSalesService.cartModel.hasProProduct();
        this.customerDetails = this.customerService.customer;
        if (!this.customerDetails){
            return;
        }
        // Hack for statusChange with asyncValidator
        // eslint-disable-next-line max-len
        // cf : https://stackoverflow.com/questions/58817647/form-stuck-in-pending-status-with-async-validator-when-value-changed-on-construc#comment103940727_58817647
        setTimeout(()=>this._setCustomerInfoInForm(),250);
    }

    public submit(): void {
        this.isLoading = true;
        this._markFormAsReadOnly();
        const [
            day, month, year
        ] = this.form.get('birthDate').value.split('/');
        const birthDate: Date = new Date(year,parseFloat(month) - 1,day);
        const data: Partial<CustomerProGpDetailsModel> = {
            ...this.customerService.customer,
            ...this.form.getRawValue(),
            birthDate,
            isUpdated: true
        };

        let simUpdateObs$: Observable<null> = of(null);
        const plan: Product = this.cartTeleSalesService.cartModel.getAllProducts()
            .find(p => ProductFactory.Is(p, Plan) && !ProductFactory.Is(p, Fai));
        if (plan) {
            const quoteIndex: number = this.cartTeleSalesService.cartModel.getProductQuoteIndex(plan.gencode);
            simUpdateObs$ = this.simService.updateSimProduct(this.cartTeleSalesService.cartModel, plan, quoteIndex)
                .pipe(
                    mergeMap((sim) => this.cartTeleSalesService.addProduct(sim, quoteIndex, false).pipe(map(() => true))),
                    map((refresh: boolean) => refresh ? this.cartTeleSalesService.refresh() : of(null)),
                    map(() => null)
                );
        }

        const bypassCheckEmail = (data.isClient || data.idPerson) &&
            (this.customerService?.customer?.email === this.form.get('email').value);

        simUpdateObs$.pipe(
            mergeMap(() => bypassCheckEmail ? of(null) : this.customerService.checkEmail(data.email).pipe(
                catchError(err => {
                    if (err?.emailContact === true) {
                        this.form.get('email').setErrors({knownCustomerEmail: true});
                    }
                    return throwError(null);
                }),
            )),
            mergeMap(() =>
                this.customerService.updateCustomer(
                    this.customerService.customer instanceof CustomerProDetailsModel ?
                        new CustomerProDetailsModel(data) :
                        new CustomerDetailsModel(data)
                ).pipe(
                    catchError(err=>{
                        if (err?.error?.codeRetour === 'login_deja_existant') {
                            this.form.get('email').setErrors({alreadyCustomer: true});
                        }
                        return throwError(null);
                    }),
                    delayWhen(()=> this.salesService.createOrUpdateCart()),
                    delayWhen(()=> this.salesService.createOrUpdateQuote(0)),
                    delayWhen(()=> this.salesService.createOrUpdateQuote(1)),
                    delayWhen(()=> this.salesService.addCoupon(this.cartTeleSalesService.cartModel.promotions.coupon)),
                    delayWhen(()=> this.salesService.addManualPromotion(this.cartTeleSalesService.cartModel.promotions.manual[0])),
                )
            ),
        ).subscribe({next: (customer)=>{
            this.cartTeleSalesService.setIdPerson(customer?.idPerson);
            this.stepperService.goToNextStep();
            this._markFormAsReadOnly(false);
        }, error: () => {
            this.isLoading = false;
            this._markFormAsReadOnly(false);
        }});
    }

    public prefillFields(): void {
        const ramdom = Math.floor(Math.random() * 1e8); // NOSONAR
        const email = `monemail-${ramdom}@bouyguestelecom.fr`;
        const prefilledValues: BasicObject = {
            email,
            // eslint-disable-next-line spaced-comment
            password: 'monM0tdepasse!', //NOSONAR
            gender: 'M',
            firstname: 'Jean',
            lastname: 'DUPONT',
            birthDate: '31/12/1985',
            birthDepartment: '93',
            phone: '0612345678'
        };
        this.form.patchValue(prefilledValues);
    }

    private _setCustomerInfoInForm(): void {
        if (this.customerDetails.idPerson) {
            this.isValidatingAutomaticStep = true;
            this._markFormAsReadOnly();
        }
        this.form.statusChanges.pipe(
            filter((status)=>status !== 'PENDING'),
            take(1),
            delay(100) // If we don't have email status change during patchValue so the initial state are not setted. So we add small delay
        ).subscribe(()=>{
            if (this.form.status === 'VALID' &&
                this.activatedRoute.snapshot.queryParams.autoSubmit !== 'false' &&
                (this.customerDetails.isClient || !this.customerDetails.idIdentity)){
                this.submit();
            } else {
                this._lockInputNotUpdatable();
            }
        });
        this.form.patchValue({
            email:this.customerService.customer.email,
            firstname:this.customerService.customer.firstname,
            lastname:this.customerService.customer.lastname,
            birthDate:this.customerService.customer.birthDate ?
                format(parseISO(this.customerService.customer.birthDate.toJSON()),'dd/MM/yyyy') : '',
            ...(this.customerService.customer.birthDepartment ? { birthDepartment:
                regions.find((region)=>this.customerService.customer.birthDepartment === region.code).code} : {}),
            gender:this.customerService.customer.gender,
            phone:this.customerService.customer.phone
        });
        for (const controlsKey in this.form.controls) {
            (this.form.get(controlsKey) as CustomFormControl).setReadonly(true);
            if (this.form.controls[controlsKey].value){
                this.form.get(controlsKey).markAsTouched();
            }
        }
    }

    private _lockInputNotUpdatable(): void {
        this.isValidatingAutomaticStep = false;
        for (const controlsKey in this.form.controls) {
            if (this.form.controls[controlsKey].status === 'VALID' &&
                controlsKey !== 'email' &&
                !this.customerService.customer.isUpdatable) {
                (this.form.get(controlsKey) as CustomFormControl).setReadonly(true);
            } else {
                (this.form.get(controlsKey) as CustomFormControl).setReadonly(false);
            }
        }
        (this.form.get('email') as CustomFormControl).setReadonly(false);
        if (this.customerService.customer instanceof CustomerProDetailsModel && this.customerService.customer.company.legalRepresentative){
            if (this.customerService.customer.company.legalRepresentative.gender){
                (this.form.get('gender') as CustomFormControl).setReadonly(true);
            }
            if (this.customerService.customer.company.legalRepresentative.firstName){
                (this.form.get('firstname') as CustomFormControl).setReadonly(true);
            }
            if (this.customerService.customer.company.legalRepresentative.lastName){
                (this.form.get('lastname') as CustomFormControl).setReadonly(true);
            }
            if (this.customerService.customer.company.legalRepresentative.birthDate){
                (this.form.get('birthDate') as CustomFormControl).setReadonly(true);
            }
        }
    }

    private _markFormAsReadOnly(isReadonly: boolean = true): void {
        for (const controlsKey in this.form.controls) {
            if (isReadonly) {
                (this.form.get(controlsKey) as CustomFormControl).setReadonly(true);
            } else {
                this._lockInputNotUpdatable();
            }
        }
    }

    @bind
    private _emailApiFeedbackValidator(control: AbstractControl): Observable<ValidationErrors | null>{
        this.isLoading = true;
        return of(control.value).pipe(delay(500),switchMap((value)=>
            this.validatorsService.mailValidation(value).pipe(
                finalize(() => this.isLoading = false),
                map(()=>null),
                catchError((emailValidation)=> {
                    const errors: ValidationErrors = {};
                    if (emailValidation.userGeneric || !emailValidation.correctEmailSyntax ||
                        !emailValidation.validEmail || emailValidation.temporaryEmail ||
                        !emailValidation.domainExist){
                        errors.badEmail = true;
                    }
                    if (emailValidation.domainBbox && !this.customerService.customer.isClient){
                        errors.domainBbox = true;
                    }
                    return of(errors);
                })
            )
        ));
    }

    @bind
    private _birthDateValidator(control: AbstractControl): ValidationErrors | null {
        if (!control.value){return null;}
        const splittedDate = control.value.split('/');
        if (splittedDate.length === 3) {
            const birthDate = new Date(splittedDate[2], splittedDate[1], splittedDate[0]);
            const timeDiff = Math.abs(Date.now() - birthDate.getTime());
            const age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
            if (age < 16){return {tooYoung:true};}
        }
        return null;
    }

}
