import { Component, Output, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import autocomplete from 'autocompleter';
import { ConfigurationService } from '@common-modules';

@Component({
    selector: 'tlv-smart-tvs-list',
    templateUrl: './smart-tvs-list.component.html',
    styleUrls: ['./smart-tvs-list.component.scss']
})
export class SmartTvsListComponent implements OnInit {

    @ViewChild('serialInput') serialInput: ElementRef;

    public tvSelected: string;

    @Output()
    public inputValidatedEvent = new EventEmitter<boolean>();

    private _inputTouched = false;
    private _automaticOutput = true;
    private _fieldAutocomplete: any;
    private _tvList: string[];

    constructor(private configurationService: ConfigurationService) { }

    public ngOnInit(): void {
        this.configurationService.getConfigurations().subscribe((config)=>{
            this._tvList = config.fai.pusan_lot3.tv_list.split(',').map(ref => ref.trim());
            if (this._tvList.length) {
                this._handleAutocomplete();
            }
        });
    }

    public isValidForm(): boolean {
        return this._tvList.some((ref: string) => ref.trim().toLocaleLowerCase() === this.tvSelected.toLocaleLowerCase());
    }

    public handleValidation(value: boolean): void {
        this.inputValidatedEvent.emit(value);
    }

    private _handleAutocomplete(): void {
        this._fieldAutocomplete = autocomplete({
            onSelect: (item: any) => {
                this.tvSelected = item.label;

                if (this.isValidForm() && this._automaticOutput) {
                    this.handleValidation(true);
                }
            },
            input: this.serialInput.nativeElement,
            minLength: 0,
            emptyMsg: 'Référence incompatible',
            render: (item, currentValue) => this._highlightAndRender(item, currentValue),
            className: 'autocomplete-customizations',
            fetch: (text, update) => {
                text = text.toLowerCase().trim();
                if (text) {
                    const suggestions: {label: string}[] = this._tvList
                        .map(input => ({label: input}))
                        .filter(n => n.label.toLowerCase().includes(text));
                    this._inputTouched = true;

                    if (suggestions.length) {
                        update(suggestions.slice(0, 10));
                    } else {
                        this.handleValidation(false);
                        update([]);
                    }
                } else {
                    this.handleValidation(false);
                }
            },
            debounceWaitMs: 0,
            customize: (input, inputRect, container, maxHeight) => {
                this._positionResultsAboveInput(inputRect, container, maxHeight);
            },
            preventSubmit: true,
            showOnFocus: true
        });
    }

    private _highlightAndRender(item, currentValue: string): HTMLDivElement {

        const div = document.createElement('div');
        const textInput = currentValue.trim();

        div.setAttribute('class', 'autocomplete-item');

        if (!textInput || textInput && item.label.toLowerCase().indexOf(textInput.toLowerCase()) === -1) {
            div.innerHTML = item.label;
            return div;
        }
        const replacedValue = new RegExp(textInput, 'gi');
        div.innerHTML = item.label.replace(replacedValue, `<span class="autocomplete-highlight">${currentValue.toUpperCase()}</span>`);
        return div;
    }

    private _positionResultsAboveInput(inputRect, container: HTMLDivElement, maxHeight: number): void {

        if (maxHeight < 100) {
            const scrollTop = window.scrollY || document.documentElement.scrollTop;

            container.style.top = '';
            container.style.bottom = `${(document.body.clientHeight - inputRect.top) - scrollTop}px`;
            container.style.maxHeight = 'none';
        }
    }

}
